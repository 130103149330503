import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { Spin } from "antd";
import VendorDashboard from "./pages/Vendor/VendorDashboard";
import JobCard from "./pages/Vendor/vendorRequirementDesc";
import YourApplication from "./pages/Vendor/YourApplication";
// import CandidateForm from "./pages/candidateOnBoard";
import Invoice from "./pages/Invoice";
import InvoiceForm from "./pages/autoGenerateInvoice";
import UpdateInvoiceForm from "./pages/updateInvoice";
import TimeSheet from "./pages/TimeSheet";
import VendorProfile from "./pages/Vendor/VendorProfilePage";
import EditVendorProfile from "./pages/Vendor/vendorProfileEditPage";
// import VendorAnalytics from "./pages/Vendor/vendorAnalytics";
import HomeVendor from "./pages/Vendor/dashboardVendor";
import TemplateSelection from "./pages/TemplateSelection";

export default function BasePage(props) {
  const userRole = localStorage.getItem("userRole");
  let userDetails = {};
  if (userRole !== "company" || "vendor" || "candidate") {
    userDetails = JSON.parse(localStorage.getItem("userDetails"));
  }
  return (
    <Suspense
      fallback={
        <Spin
          size="large"
          style={{ display: "block", margin: "auto", marginTop: "20%" }}
        />
      }
    >
      <Switch>
        {/* Redirect from root URL to appropriate dashboard */}
        {userRole == "company" &&
          props.CompanyRoutesConfig.length > 0 &&
          props.CompanyRoutesConfig.map((route, index) => (
            <Route path={route.path} component={route.component} key={index} />
          ))}
        {(userRole !== "company" ||
          userRole !== "vendor" ||
          userRole !== "candidate") &&
          props?.CompanyRoutesConfig?.map((route, index) => {
            const hasPermission = userDetails?.roleId?.permissions?.some(
              (permission) =>
                route.permission && route.permission.includes(permission)
            );
            return hasPermission ? (
              <Route
                path={route.path}
                component={route.component}
                key={index}
              />
            ) : null;
          })}
        <Route path="/vendor/requirement" component={VendorDashboard} />
        <Route path="/vendor/requirement_description" component={JobCard} />
        <Route path="/vendor/applications" component={YourApplication} />
        {/* <Route path="/vendor/Candidate/create" component={CandidateForm} /> */}
        <Route path="/vendor/invoices" component={Invoice} />
        <Route path="/vendor/generate_invoices" component={InvoiceForm} />
        <Route path="/vendor/update_invoices" component={UpdateInvoiceForm} />
        <Route path="/vendor/timesheet" component={TimeSheet} />
        <Route path="/vendor/Profile" component={VendorProfile} />
        <Route path="/vendor/Profile-update" component={EditVendorProfile} />
        <Route path="/vendor/Dashboard" component={HomeVendor} />
        <Route path="/company/template1" component={TemplateSelection} />

        {props.userRole === "company" ? (
          <Redirect from="/" to="/company/dashboard" />
        ) : props.userRole === "vendor" ? (
          <Redirect from="/" to="/vendor/Dashboard" />
        ) : (
          <Redirect from="/" to="/company/requirements" />
        )}
        <Redirect to="/error" />
      </Switch>
    </Suspense>
  );
}

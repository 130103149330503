import React, { useState } from "react";
import {
  Form,
  Input,
  DatePicker,
  Button,
  Card,
  Typography,
  Row,
  Col,
  TimePicker,
  message,
} from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { postReq } from "../api";
import { toastNotification } from "../utils/toasthelper";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const { Title } = Typography;

const TaskForm = ({ record }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState([
    { id: 1, taskName: "", taskDesc: "", startTime: null, endTime: null },
  ]);

  const handleAddTask = () => {
    setTasks([
      ...tasks,
      {
        id: tasks.length + 1,
        taskName: "",
        taskDesc: "",
        startTime: null,
        endTime: null,
      },
    ]);
  };

  const handleRemoveTask = (id) => {
    if (tasks.length === 1) {
      message.warning("At least one task must be present.");
      return;
    }
    setTasks(tasks.filter((task) => task.id !== id));
  };

  const handleTaskChange = (id, key, value) => {
    const updatedTasks = tasks.map((task) => {
      if (task.id === id) {
        if (
          key === "startTime" &&
          task.endTime &&
          dayjs(value).isAfter(task.endTime)
        ) {
          message.error("Start time cannot be after the end time.");
          return task;
        }
        if (
          key === "endTime" &&
          task.startTime &&
          dayjs(value).isBefore(task.startTime)
        ) {
          message.error("End time cannot be before the start time.");
          return task;
        }
        return { ...task, [key]: value };
      }
      return task;
    });
    setTasks(updatedTasks);
  };

  const handleFinish = async (values) => {
    try {
      setLoading(true);
      const payload = {
        date: values.date ? values.date.toISOString() : null,
        projectName: values.projectName,
        tasks: tasks.map((task) => ({
          taskName: task.taskName,
          taskDesc: task.taskDesc,
          startTime: task.startTime
            ? dayjs(task.startTime).toISOString()
            : null,
          endTime: task.endTime ? dayjs(task.endTime).toISOString() : null,
        })),
      };
      const response = await postReq("/company/create-timesheet", payload);
      if (response.status == 1) {
        toastNotification("success", response.message);
        history.push("/company/TimeSheet");
      } else {
        toastNotification("error", response.message);
      }
    } catch (error) {
      toastNotification("error", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card
      title={
        <Title
          level={4}
          style={{ marginBottom: 0, fontWeight: "bold", color: "#13487D" }}
        >
          Add Tasks
        </Title>
      }
      bordered={false}
      style={{ borderRadius: "18px" }}
    >
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Select Date"
              name="date"
              rules={[{ required: true, message: "Please select a date." }]}
            >
              <DatePicker
                style={{ width: "100%" }}
                disabledDate={(current) => current && current > dayjs()}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Project Name"
              name="projectName"
              rules={[
                { required: true, message: "Please enter the project name." },
              ]}
            >
              <Input placeholder="Enter Project Name" />
            </Form.Item>
          </Col>
        </Row>

        {tasks.map((task, index) => (
          <Card
            key={task.id}
            style={{
              marginBottom: 16,
              border: "1px solid #d9d9d9",
              borderRadius: 8,
            }}
          >
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  label={`Task ${index + 1}`}
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please enter the task name.",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Task Name"
                    value={task.taskName}
                    onChange={(e) =>
                      handleTaskChange(task.id, "taskName", e.target.value)
                    }
                  />
                </Form.Item>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Start Time" required>
                      <TimePicker
                        format="HH:mm"
                        style={{ width: "100%" }}
                        value={task.startTime ? dayjs(task.startTime) : null}
                        onChange={(value) =>
                          handleTaskChange(task.id, "startTime", value)
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="End Time" required>
                      <TimePicker
                        format="HH:mm"
                        style={{ width: "100%" }}
                        value={task.endTime ? dayjs(task.endTime) : null}
                        onChange={(value) =>
                          handleTaskChange(task.id, "endTime", value)
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Task Description"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please enter the project name.",
                    },
                  ]}
                >
                  <Input.TextArea
                    placeholder="Enter Task Description"
                    value={task.taskDesc}
                    autoSize={{ minRows: 5, maxRows: 6 }}
                    onChange={(e) =>
                      handleTaskChange(task.id, "taskDesc", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} style={{ textAlign: "right" }}>
                <Button
                  type="danger"
                  icon={<MinusOutlined />}
                  onClick={() => handleRemoveTask(task.id)}
                >
                  Remove Task
                </Button>
              </Col>
            </Row>
          </Card>
        ))}

        <div
          style={{ display: "flex", gap: "8px", justifyContent: "flex-end" }}
        >
          <Button
            type="dashed"
            icon={<PlusOutlined />}
            onClick={handleAddTask}
            style={{ marginTop: 16 }}
          >
            Add Task
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            style={{ marginTop: 16 }}
          >
            Submit
          </Button>
        </div>
      </Form>
    </Card>
  );
};

export default TaskForm;

// import React, { useState } from "react";
// import {
//   Form,
//   Input,
//   DatePicker,
//   Button,
//   Card,
//   Typography,
//   Row,
//   Col,
//   TimePicker,
//   message,
// } from "antd";
// import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
// import dayjs from "dayjs";
// import { postReq } from "../api";
// import { toastNotification } from "../utils/toasthelper";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import moment from "moment";

// const { Title } = Typography;

// const TaskForm = ({ record }) => {
//   const [form] = Form.useForm();
//   const history = useHistory();
//   const [loading, setLoading] = useState(false);
//   const [tasks, setTasks] = useState([
//     { id: 1, taskName: "", taskDesc: "", startTime: null, endTime: null },
//   ]);

//   const handleAddTask = () => {
//     setTasks([
//       ...tasks,
//       {
//         id: tasks.length + 1,
//         taskName: "",
//         taskDesc: "",
//         startTime: null,
//         endTime: null,
//       },
//     ]);
//   };

//   const handleRemoveTask = (id) => {
//     if (tasks.length === 1) {
//       message.warning("At least one task must be present.");
//       return;
//     }
//     setTasks(tasks.filter((task) => task.id !== id));
//   };

//   const handleFinish = async (values) => {
//     try {
//       setLoading(true);
//       const payload = {
//         date: values.date ? values.date.toISOString() : null,
//         projectName: values.projectName,
//         tasks: tasks.map((task) => ({
//           taskName: task.taskName,
//           taskDesc: task.taskDesc,
//           startTime: task.startTime ? task.startTime.toISOString() : null,
//           endTime: task.endTime ? task.endTime.toISOString() : null,
//         })),
//       };
//       const response = await postReq("/company/create-timesheet", payload);
//       if (response.status === 1) {
//         toastNotification("success", response.message);
//         setLoading(false);
//         history.push("/company/TimeSheet");
//       } else {
//         toastNotification("error", response.message);
//         setLoading(false);
//       }
//     } catch (error) {
//       toastNotification("error", error.message);
//       setLoading(false);
//     }
//   };

//   const handleTaskChange = (id, key, value) => {
//     const updatedTasks = tasks.map((task, index) => {
//       if (task.id === id) {
//         // Validate startTime against the previous task's endTime
//         if (key === "startTime" && index > 0) {
//           const prevTask = tasks[index - 1];
//           if (
//             prevTask.endTime &&
//             moment(value).isBefore(moment(prevTask.endTime))
//           ) {
//             message.error(
//               `Start time of Task ${
//                 index + 1
//               } must be after the end time of Task ${index}.`
//             );
//             return task; // Keep the current state without updating
//           }
//         }
//         if (key === "endTime" && task.startTime) {
//           if (moment(value).isBefore(moment(task.startTime))) {
//             message.error(
//               `End time of Task ${
//                 index + 1
//               } must not be earlier than its start time.`
//             );
//             return task; // Keep the current state without updating
//           }
//         }

//         return { ...task, [key]: value };
//       }
//       return task;
//     });
//     setTasks(updatedTasks);
//   };

//   // const handleTaskChange = (id, key, value) => {
//   //   const updatedTasks = tasks.map((task) =>
//   //     task.id === id ? { ...task, [key]: value } : task
//   //   );
//   //   setTasks(updatedTasks);
//   // };

//   return (
//     <Card
//       title={
//         <div>
//           <Title
//             level={4}
//             style={{ marginBottom: 0, fontWeight: "bold", color: "#13487D" }}
//           >
//             Add Tasks
//           </Title>
//         </div>
//       }
//       bordered={false}
//       style={{ borderRadius: "18px" }}
//     >
//       <Form form={form} layout="vertical" onFinish={handleFinish}>
//         <Row gutter={16}>
//           <Col span={12}>
//             <Form.Item
//               label="Select Date"
//               name="date"
//               rules={[{ required: true, message: "Please select a date." }]}
//             >
//               <DatePicker
//                 style={{ width: "100%" }}
//                 disabledDate={(current) => {
//                   const startOfWeek = moment().startOf("week");
//                   const endOfWeek = moment().endOf("week");
//                   const today = moment().endOf("day");

//                   return (
//                     !current ||
//                     current < startOfWeek ||
//                     current > endOfWeek ||
//                     current > today
//                   );
//                 }}
//               />
//             </Form.Item>
//           </Col>
//           <Col span={12}>
//             <Form.Item
//               label="Project Name"
//               name="projectName"
//               rules={[
//                 { required: true, message: "Please enter the project name." },
//               ]}
//             >
//               <Input placeholder="Enter Project Name" />
//             </Form.Item>
//           </Col>
//         </Row>

//         <div>
//   {tasks.map((task, index) => (
//     <Card
//       key={task.id}
//       style={{
//         marginBottom: 16,
//         border: "1px solid #d9d9d9",
//         borderRadius: 8,
//       }}
//     >
//       <Row gutter={16}>
//         {/* First Column: Task Name, Start Time, End Time */}
//         <Col xs={24} md={12}>
//           <Form.Item label={`Task ${index + 1}`} required>
//             <Input
//               placeholder="Enter Task Name"
//               value={task.taskName}
//               onChange={(e) =>
//                 handleTaskChange(task.id, "taskName", e.target.value)
//               }
//             />
//           </Form.Item>
//           <Row gutter={16}>
//             <Col span={12}>
//               <Form.Item label="Start Time" required>
//                 <TimePicker
//                   format="HH:mm"
//                   style={{ width: "100%" }}
//                   value={task.startTime ? dayjs(task.startTime) : null}
//                   onChange={(value) =>
//                     handleTaskChange(task.id, "startTime", value)
//                   }
//                 />
//               </Form.Item>
//             </Col>
//             <Col span={12}>
//               <Form.Item label="End Time" required>
//                 <TimePicker
//                   format="HH:mm"
//                   style={{ width: "100%" }}
//                   value={task.endTime ? dayjs(task.endTime) : null}
//                   onChange={(value) =>
//                     handleTaskChange(task.id, "endTime", value)
//                   }
//                 />
//               </Form.Item>
//             </Col>
//           </Row>
//         </Col>

//         {/* Second Column: Task Description */}
//         <Col xs={24} md={12}>
//           <Form.Item label="Task Description" required>
//             <Input.TextArea
//               placeholder="Enter Task Description"
//               value={task.taskDesc}
//               autoSize={{ minRows: 2, maxRows: 6 }}
//               onChange={(e) =>
//                 handleTaskChange(task.id, "taskDesc", e.target.value)
//               }
//             />
//           </Form.Item>
//         </Col>

//         {/* Remove Task Button */}
//         <Col xs={24} style={{ textAlign: "right" }}>
//           <Button
//             type="danger"
//             icon={<MinusOutlined />}
//             onClick={() => handleRemoveTask(task.id)}
//           >
//             Remove Task
//           </Button>
//         </Col>
//       </Row>
//     </Card>
//   ))}
// </div>

//         {/* <div>
//           {tasks.map((task, index) => (
//             <Card
//               key={task.id}
//               style={{
//                 marginBottom: 16,
//                 border: "1px solid #d9d9d9",
//                 borderRadius: 8,
//               }}
//             >
//               <Row gutter={16}>
//                 {/* Task Name and Task Description */}
//                 <Col xs={24} md={12}>
//                   <Form.Item label={`Task ${index + 1}`} required>
//                     <Input
//                       placeholder="Enter Task Name"
//                       value={task.taskName}
//                       onChange={(e) =>
//                         handleTaskChange(task.id, "taskName", e.target.value)
//                       }
//                     />
//                   </Form.Item>
//                 </Col>
//                 <Col xs={24} md={12}>
//                   <Form.Item label="Task Description" required>
//                     <Input.TextArea
//                       placeholder="Enter Task Description"
//                       value={task.taskDesc}
//                       autoSize={{ minRows: 2, maxRows: 6 }}
//                       onChange={(e) =>
//                         handleTaskChange(task.id, "taskDesc", e.target.value)
//                       }
//                     />
//                   </Form.Item>
//                 </Col>

//                 {/* Start Time and End Time */}
//                 <Col xs={24} md={6}>
//                   <Form.Item label="Start Time" required>
//                     <TimePicker
//                       format="HH:mm"
//                       style={{ width: "100%" }}
//                       value={task.startTime ? dayjs(task.startTime) : null}
//                       onChange={(value) =>
//                         handleTaskChange(task.id, "startTime", value)
//                       }
//                     />
//                   </Form.Item>
//                 </Col>
//                 <Col xs={24} md={6}>
//                   <Form.Item label="End Time" required>
//                     <TimePicker
//                       format="HH:mm"
//                       style={{ width: "100%" }}
//                       value={task.endTime ? dayjs(task.endTime) : null}
//                       onChange={(value) =>
//                         handleTaskChange(task.id, "endTime", value)
//                       }
//                     />
//                   </Form.Item>
//                 </Col>

//                 {/* Remove Task Button */}
//                 <Col xs={24} style={{ textAlign: "right" }}>
//                   <Button
//                     type="danger"
//                     icon={<MinusOutlined />}
//                     onClick={() => handleRemoveTask(task.id)}
//                   >
//                     Remove Task
//                   </Button>
//                 </Col>
//               </Row>
//             </Card>
//           ))}
//         </div> */}

//         <div style={{ display: "flex", gap: "8px", justifyContent: "end" }}>
//           <Button
//             type="dashed"
//             icon={<PlusOutlined />}
//             onClick={handleAddTask}
//             style={{ marginTop: 16 }}
//           >
//             Add Task
//           </Button>
//           <Form.Item style={{ marginTop: 16 }}>
//             <Button type="primary" htmlType="submit" block loading={loading}>
//               Submit
//             </Button>
//           </Form.Item>
//         </div>
//       </Form>
//     </Card>
//   );
// };

// export default TaskForm;

import React, { useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css"; // Include Quill's styles
import { postReq } from "../api";

const Delta = Quill.import("delta");

const EditorTemplate = () => {
  const [range, setRange] = useState(null);
  const [lastChange, setLastChange] = useState(null);
  const [readOnly, setReadOnly] = useState(false);

  const quillRef = useRef(null); // Initialize the ref

  const modules = {
    toolbar: [
      [{ font: [] }, { size: [] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [{ direction: "rtl" }, { align: [] }],
      ["link", "image", "video", "formula"],
      ["clean"],
    ],
  };

  const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "script",
    "header",
    "blockquote",
    "code-block",
    "list",
    "bullet",
    "indent",
    "direction",
    "align",
    "link",
    "image",
    "video",
    "formula",
  ];
  const handleSubmit = async () => {
    if (!quillRef.current) {
      alert("Editor is not initialized.");
      return;
    }

    const editor = quillRef.current.getEditor(); // Safely access the editor instance
    const htmlContent = editor.root.innerHTML; // Get HTML content
    const deltaContent = editor.getContents(); // Get Delta format content

    const payload = { htmlContent, deltaContent };

    try {
      const response = await postReq("/vendor/generate_resume_editor", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        alert("Data submitted successfully!");
      } else {
        const error = await response.json();
        console.error("Server Error:", error);
        alert("Failed to submit data.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while submitting data.");
    }
  };

  return (
    <div>
      <ReactQuill
        ref={quillRef} // Assign the ref to ReactQuill
        readOnly={readOnly}
        defaultValue={new Delta()
          .insert("Hello")
          .insert("\n", { header: 1 })
          .insert("Some ")
          .insert("initial", { bold: true })
          .insert(" ")
          .insert("content", { underline: true })
          .insert("\n")}
        onChange={() => {
          if (quillRef.current) {
            setLastChange(quillRef.current.getEditor().getContents());
          }
        }}
        onChangeSelection={setRange}
        theme="snow"
        modules={modules}
        formats={formats}
      />
      {/* <div style={{ marginTop: "20px" }}>
        <label style={{ marginRight: "10px" }}>
          Read Only:
          <input
            type="checkbox"
            checked={readOnly}
            onChange={(e) => setReadOnly(e.target.checked)}
            style={{ marginLeft: "5px" }}
          />
        </label>
        <button
          type="button"
          onClick={() => {
            if (quillRef.current) {
              alert(
                `Content Length: ${quillRef.current.getEditor().getLength()}`
              );
            } else {
              alert("Editor is not initialized.");
            }
          }}
          style={{
            marginLeft: "10px",
            padding: "5px 10px",
            backgroundColor: "#28a745",
            color: "white",
            border: "none",
            cursor: "pointer",
          }}
        >
          Get Content Length
        </button>
      </div> */}
      <div style={{ marginTop: "20px" }}>
        <button
          type="button"
          onClick={handleSubmit}
          style={{
            padding: "10px 20px",
            backgroundColor: "#007bff",
            color: "white",
            border: "none",
            cursor: "pointer",
          }}
        >
          Submit Data
        </button>
      </div>
    </div>
  );
};

export default EditorTemplate;

import { Button, Col, Form, Input, message, Row, Select, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { postReq } from "../../api";
import { useParams, useHistory } from "react-router-dom";
const { Option } = Select;

const OnBoardPage = (companyId) => {
  const [techNicheOptions, setTechNicheOptions] = useState([]);
  const [optionSelected, setoptionSelected] = useState([]);
  const { form } = Form.useForm();
  const history = useHistory();
  const handleTagsData = async () => {
    try {
      const callResponse = await postReq("/common/getTags", {
        type: "techNiche",
        companyId: companyId.vendorId,
        // companyId: "6631dfd0eb64c21aab3760bc",
      });

      if (callResponse.status === 1) {
        setTechNicheOptions(callResponse.data);
      }
    } catch (error) {
      console.log("error ", error);
    }
  };

  useEffect(() => {
    handleTagsData();
  }, []);

  const [organizationLogo, setOrganizationLogo] = useState(null);
  const [gstStatementFile, setGstStatementFile] = useState(null);

  const handleFinish = async (values) => {
    const formData = new FormData();

    // Append form fields
    Object.keys(values).forEach((key) => {
      if (key !== "technologyNiche") {
        formData.append(key, values[key]);
      }
    });
    if (values.technologyNiche && values.technologyNiche.length > 0) {
      for (let i = 0; i < values.technologyNiche.length; i++) {
        formData.append(`technologyNiche[${i}]`, values.technologyNiche[i]);
      }
    }
    formData.append("companyId", "679c92304e6dc68f31efec72");
    // Append files
    if (organizationLogo) {
      formData.append("organizationLogo", organizationLogo);
    }
    if (gstStatementFile) {
      formData.append("gstStatementFile", gstStatementFile);
    }

    try {
      const response = await postReq("/vendor/register", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      message.success(
        response.data.message || "Vendor registered successfully!"
      );
      history.push("/auth/vendor/login");
    } catch (error) {
      message.error(
        error.response?.data?.message || "Failed to register vendor"
      );
    }
  };

  const handleFileChange = (info, setFileCallback) => {
    const { file } = info;
    const isValidFile = validateFile(file);
    if (isValidFile) {
      setFileCallback(file);
    }
  };

  const validateFile = (file) => {
    if (!file) return false;

    // Validate file type and size
    const isPngOrPdf =
      file.type === "image/png" || file.type === "application/pdf";
    const isSmallerThan2MB = file.size / 1024 / 1024 < 2;

    if (!isPngOrPdf) {
      message.error("Only PNG or PDF files are allowed!");
      return false;
    }

    if (!isSmallerThan2MB) {
      message.error("File size must be smaller than 2MB!");
      return false;
    }

    return true;
  };

  const handleFinishFailed = () => {
    console.log("hereFailed");
  };
  return (
    <div style={{ margin: "0 auto", padding: "20px" }}>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
        onFinishFailed={handleFinishFailed}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                { required: true, message: "Name is required" },
                { min: 5, message: "Minimum 5 letters" },
                { max: 50, message: "Maximum 50 letters" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Email"
              name="vendorEmail"
              rules={[
                { required: true, message: "Email is required" },
                { type: "email", message: "Enter valid email format" },
                { min: 5, message: "Minimum 5 letters" },
                { max: 50, message: "Maximum 50 letters" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="GST Number"
              name="vendorCompanyGstNo"
              rules={[
                { required: true, message: "GST number is required" },
                { min: 5, message: "Minimum 5 letters" },
                { max: 50, message: "Maximum 50 letters" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Company Name"
              name="vendorCompanyName"
              rules={[
                { required: true, message: "Company name is required" },
                { min: 5, message: "Minimum 5 characters" },
                { max: 50, message: "Maximum 50 characters" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        {/* Continue wrapping pairs of Form.Item components in Row and Col as needed */}
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Company Strength"
              name="vendorCompanyStrength"
              rules={[
                { required: true, message: "Company strength is required" },
                { min: 2, message: "Company strength is too low" },
                { max: 4, message: "Company strength is too big" },
                {
                  pattern: /^[0-9]+$/,
                  message: "Company strength should only be in numbers",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Company Website URL"
              name="vendorCompanyWebsiteUrl"
              rules={[
                { required: true, message: "Company website URL is required" },
                {
                  pattern:
                    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/gi,
                  message: "Invalid website URL",
                },
                { min: 5, message: "Minimum 5 letters" },
                { max: 50, message: "Maximum 50 letters" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        {/* Add remaining form items in rows */}
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Technology Niche"
              name="technologyNiche"
              rules={[
                { required: true, message: "Technology niche is required" },
                {
                  type: "array",
                  min: 1,
                  message: "At least one niche is required",
                },
                {
                  type: "array",
                  max: 3,
                  message: "You can add at most 3 niches",
                },
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Select up to 3 niches"
                onChange={(selected) => setoptionSelected(selected)}
                maxTagCount="responsive" // Automatically adjusts based on space
                style={{ maxHeight: "100px", overflowY: "auto" }}
              >
                {techNicheOptions.map((data) => (
                  <Option
                    key={data._id}
                    value={data._id}
                    disabled={
                      optionSelected.length >= 3 &&
                      !optionSelected.includes(data._id)
                    }
                  >
                    {data.value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="CIN Number"
              name="cinNO"
              rules={[{ required: true, message: "CIN Number is required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Company PAN Number"
              name="ceoPanNo"
              rules={[
                { required: true, message: "CEO PAN Number is required" },
                {
                  pattern: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                  message: "Invalid PAN format",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Company Aadhaar Number"
              name="ceoAdharNo"
              rules={[
                { required: true, message: "CEO Aadhaar Number is required" },
                {
                  pattern: /^[2-9]{1}[0-9]{11}$/,
                  message: "Invalid Aadhaar format",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="ISO Number"
              name="isoNo"
              rules={[{ required: true, message: "ISO Number is required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="SPOC Email"
              name="communicationEmail"
              rules={[
                { required: true, message: "SPOC Email is required" },
                { type: "email", message: "Invalid email format" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="SPOC Phone no."
              name="communicationPhone"
              rules={[
                { required: true, message: "SPOC Phone no. iss required" },
                { min: 10, message: "Phone should be 10 numbers" },
                { max: 10, message: "Phone should be 10 numbers" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="SPOC Designation"
              name="communicationDesignation"
              rules={[
                { required: true, message: "SPOC Designation is required" },
                { min: 5, message: "Minimum 5 letters" },
                { max: 50, message: "Maximum 50 letters" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="TAN Number"
              name="tanNo"
              rules={[{ required: true, message: "TAN number is required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Msme Number"
              name="msmeNo"
              rules={[{ required: true, message: "msme number is required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Register Address"
              name="RegAddress"
              rules={[
                { required: true, message: "Register address is required" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Operational Address"
              name="operationalAddress"
              rules={[
                { required: true, message: "Operational address is required" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="ITR No 1"
              name="itrNo1"
              rules={[
                {
                  pattern: /^[0-9]+$/,
                  message: "ITR number should only contain numbers",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="ITR No 2"
              name="itrNo2"
              rules={[
                {
                  pattern: /^[0-9]+$/,
                  message: "ITR number should only contain numbers",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        {/* Example of file upload items */}
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Organization Logo"
              name="organizationLogo"
              rules={[
                {
                  required: true,
                  message: "Please upload the logo",
                },
              ]}
            >
              <Upload
                maxCount={1}
                beforeUpload={() => false} // Prevent automatic upload
                onChange={(info) => handleFileChange(info, setOrganizationLogo)}
                accept="image/png"
              >
                <Button>Upload PNG</Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="GST Statement File"
              name="gstStatementFile"
              rules={[
                { required: true, message: "Please upload the GST statement" },
              ]}
            >
              <Upload
                maxCount={1}
                beforeUpload={() => false} // Prevent automatic upload
                onChange={(info) => handleFileChange(info, setGstStatementFile)}
                accept="application/pdf"
              >
                <Button>Upload PDF</Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item
              style={{
                textAlign: "right",
                paddingRight: "20px",
                paddingTop: "10px",
              }}
            >
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default OnBoardPage;

import React, { useState } from "react";
import { Form, Input, Button, Select, message, Row, Col, Upload } from "antd";
import { useHistory } from "react-router-dom";
import { postReq } from "../api";
import { toastNotification } from "../utils/toasthelper";

const { Option } = Select;

const CompanyRegistration = () => {
  const [loading, setLoading] = useState(false);
  const [msaDoc, setMsaDoc] = useState(null);
  const [ndaDoc, setNdaDoc] = useState(null);
  const history = useHistory();

  const handleFormSubmission = async (values) => {
    try {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        if (key !== "technologyNiche") {
          formData.append(key, values[key]);
        }
      });
      if (msaDoc) {
        formData.append("msaDoc", msaDoc);
      }
      if (ndaDoc) {
        formData.append("ndaDoc", ndaDoc);
      }

      const postData = await postReq("/company/register", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (postData.status === 1) {
        toastNotification("success", postData.message);
        setLoading(false);
        // history.push("/auth/company/login");
      } else {
        toastNotification("error", postData.message);
        setLoading(false);
      }
    } catch (error) {
      toastNotification("error", "Something went wrong, Try again");
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    // const formData = new FormData();
    // Object.keys(values).forEach((key) => {
    //   if (Array.isArray(values[key])) {
    //     formData.append(key, JSON.stringify(values[key]));
    //   } else {
    //     formData.append(key, values[key]);
    //   }
    // });
    await handleFormSubmission(values);
  };

  const onFinishFailed = (errorInfo) => {
    message.error("Please fill all required fields correctly.");
  };

  const handleFileChange = (info, setFileCallback) => {
    const { file } = info;
    const isValidFile = validateFile(file);
    if (isValidFile) {
      setFileCallback(file);
    }
  };

  const validateFile = (file) => {
    if (!file) return false;

    // Validate file type and size
    const isPdf = file.type === "application/pdf";
    const isSmallerThan2MB = file.size / 1024 / 1024 < 2;

    if (!isPdf) {
      message.error("Only PDF files are allowed!");
      return false;
    }

    if (!isSmallerThan2MB) {
      message.error("File size must be smaller than 2MB!");
      return false;
    }

    return true;
  };
  return (
    <div style={{ margin: "0 auto", padding: "20px" }}>
      {/* <h2 style={{ textAlign: "center" }}>Company Onboarding</h2>
      <p style={{ textAlign: "center" }}>
        Enter your company details to get registered for this tool.
      </p> */}
      <Form
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{ technologyNiche: [] }}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Company Name"
              name="name"
              rules={[
                { required: true, message: "Please enter company name" },
                { min: 3, message: "Name must be at least 3 characters" },
              ]}
            >
              <Input placeholder="Company Name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Establishment Year"
              name="establishmentYear"
              rules={[
                {
                  required: true,
                  message: "Please enter establishment year",
                },
                {
                  pattern: /^[0-9]{4}$/,
                  message: "Please enter a valid year (e.g., 2022)",
                },
                {
                  validator: (_, value) =>
                    value && value > new Date().getFullYear()
                      ? Promise.reject(
                          "Year must be less than or equal to the current year"
                        )
                      : Promise.resolve(),
                },
              ]}
            >
              <Input placeholder="Year (e.g., 2022)" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Company Email"
              name="companyEmail"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please enter a valid email",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please enter password" },
                { min: 3, message: "Password must be at least 3 characters" },
                {
                  min: 8,
                  message: "Password can not be more than 8 characters",
                },
              ]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Confirm Password"
              name="changepassword"
              dependencies={["password"]}
              rules={[
                { required: true, message: "Please confirm your password" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Passwords do not match!");
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirm Password" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Company Strength"
              name="companyStrength"
              rules={[
                { required: true, message: "Please enter company strength" },
                {
                  pattern: /^[0-9]+$/,
                  message: "Strength must be a numeric value",
                },
                { max: 200, message: "Strength Can not be more than 200" },
              ]}
            >
              <Input placeholder="Company Strength" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Company Website"
              name="companyWebsite"
              rules={[
                {
                  required: true,
                  message: "Please enter company website",
                },
                {
                  type: "url",
                  message: "Please enter a valid website URL",
                },
              ]}
            >
              <Input placeholder="Website URL" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Technology Niche"
              name="technologyNiche"
              rules={[
                {
                  required: true,
                  message: "Please select at least one technology niche",
                },
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Select technology niche"
                allowClear
                maxTagCount="responsive"
              >
                <Option value="AI">AI</Option>
                <Option value="Blockchain">Blockchain</Option>
                <Option value="IoT">IoT</Option>
                <Option value="Web Development">Web Development</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Contact Person Email"
              name="contactPerson"
              rules={[
                {
                  required: true,
                  message: "Please enter contact person's email",
                },
                { type: "email", message: "Please enter a valid email" },
              ]}
            >
              <Input placeholder="Contact Person Email" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Payment Cycle (in days)"
              name="paymentCycle"
              rules={[
                { required: true, message: "Please enter payment cycle" },
                {
                  pattern: /^[0-9]+$/,
                  message: "Payment cycle must be a numeric value",
                },
                { max: 45, message: "Maximum 45 days payment cycle" },
              ]}
            >
              <Input placeholder="Payment Cycle (e.g., 30)" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="GST No."
              name="gstNo"
              rules={[
                {
                  required: true,
                  message: "Please enter compnies gst no.",
                },
              ]}
            >
              <Input placeholder="GST No." />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Address"
              name="companyAddress"
              rules={[
                { required: true, message: "Please enter company address" },
              ]}
            >
              <Input placeholder="Address" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="PAN No."
              name="panNo"
              rules={[
                {
                  required: true,
                  message: "Please enter compnies pan no.",
                },
              ]}
            >
              <Input placeholder="PAN No." />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Aadhar No."
              name="aadharNo"
              rules={[
                { required: true, message: "Please enter company aadharNo" },
              ]}
            >
              <Input placeholder="Aadhar No." />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="MSA Doc"
              name="msaDoc"
              rules={[
                {
                  required: true,
                  message: "Please upload the MSA Document",
                },
              ]}
            >
              <Upload
                maxCount={1}
                beforeUpload={() => false} // Prevent automatic upload
                onChange={(info) => handleFileChange(info, setMsaDoc)}
                accept="application/pdf"
              >
                <Button>Upload msa</Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="NDA Doc"
              name="ndaDoc"
              rules={[
                { required: true, message: "Please upload the NDA Document" },
              ]}
            >
              <Upload
                maxCount={1}
                beforeUpload={() => false} // Prevent automatic upload
                onChange={(info) => handleFileChange(info, setNdaDoc)}
                accept="application/pdf"
              >
                <Button>Upload PDF</Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item style={{ textAlign: "right" }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                style={{}}
              >
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {/* <Form
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{ technologyNiche: [] }}
      >
        <Form.Item
          label="Company Name"
          name="name"
          rules={[
            { required: true, message: "Please enter the company name" },
            { min: 3, message: "Name must be at least 3 characters" },
          ]}
        >
          <Input placeholder="Company Name" />
        </Form.Item>

        <Form.Item
          label="Establishment Year"
          name="establishmentYear"
          rules={[
            {
              required: true,
              message: "Please enter the establishment year",
            },
            {
              pattern: /^[0-9]{4}$/,
              message: "Please enter a valid year (e.g., 2022)",
            },
            {
              validator: (_, value) =>
                value && value > new Date().getFullYear()
                  ? Promise.reject(
                      "Year must be less than or equal to the current year"
                    )
                  : Promise.resolve(),
            },
          ]}
        >
          <Input placeholder="Year (e.g., 2022)" />
        </Form.Item>

        <Form.Item
          label="Company Email"
          name="companyEmail"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please enter a valid email",
            },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            { required: true, message: "Please enter a password" },
            { min: 3, message: "Password must be at least 3 characters" },
          ]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>

        <Form.Item
          label="Confirm Password"
          name="changepassword"
          dependencies={["password"]}
          rules={[
            { required: true, message: "Please confirm your password" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject("Passwords do not match!");
              },
            }),
          ]}
        >
          <Input.Password placeholder="Confirm Password" />
        </Form.Item>

        <Form.Item
          label="Company Strength"
          name="companyStrength"
          rules={[
            { required: true, message: "Please enter company strength" },
            {
              pattern: /^[0-9]+$/,
              message: "Strength must be a numeric value",
            },
          ]}
        >
          <Input placeholder="Company Strength" />
        </Form.Item>

        <Form.Item
          label="Company Website"
          name="companyWebsite"
          rules={[
            {
              required: true,
              message: "Please enter the company website",
            },
            {
              type: "url",
              message: "Please enter a valid website URL",
            },
          ]}
        >
          <Input placeholder="Website URL" />
        </Form.Item>

        <Form.Item
          label="Technology Niche"
          name="technologyNiche"
          rules={[
            {
              required: true,
              message: "Please select at least one technology niche",
            },
          ]}
        >
          <Select
            mode="multiple"
            placeholder="Select technology niche"
            allowClear
          >
            <Option value="AI">AI</Option>
            <Option value="Blockchain">Blockchain</Option>
            <Option value="IoT">IoT</Option>
            <Option value="Web Development">Web Development</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Contact Person Email"
          name="contactPerson"
          rules={[
            {
              required: true,
              message: "Please enter the contact person's email",
            },
            { type: "email", message: "Please enter a valid email" },
          ]}
        >
          <Input placeholder="Contact Person Email" />
        </Form.Item>

        <Form.Item
          label="Payment Cycle (in days)"
          name="paymentCycle"
          rules={[
            { required: true, message: "Please enter the payment cycle" },
            {
              pattern: /^[0-9]+$/,
              message: "Payment cycle must be a numeric value",
            },
          ]}
        >
          <Input placeholder="Payment Cycle (e.g., 30)" />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            style={{ width: "100%" }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form> */}
    </div>
  );
};

export default CompanyRegistration;

import { useState, useEffect } from "react";

const useUserDetails = () => {
  const [userRole, setUserRole] = useState("");
  const [userDetails, setUserDetails] = useState("");
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    const userRoleByLocalStorage = localStorage.getItem("userRole");
    const detailsSlug =
      userRoleByLocalStorage === "company"
        ? "companyDetails"
        : userRoleByLocalStorage === "vendor"
        ? "vendorDetails"
        : "userDetails";
    const userData = getDataFromLocalStorageById(detailsSlug);
    setUserRole(userRoleByLocalStorage);
    if (userData) {
      setUserDetails(userData);
      if (detailsSlug === "userDetails" && userData?.roleId?.permissions) {
        setPermissions(userData.roleId.permissions);
      }
    }
  }, []);

  return { userRole, userDetails, permissions };
};
const getDataFromLocalStorageById = (id) => {
  // Retrieve data from localStorage
  const storedData = localStorage.getItem(id);
  // Check if data exists
  if (storedData) {
    // Parse the stored JSON data
    return JSON.parse(storedData);
  } else {
    // If data doesn't exist, return null or handle accordingly
    return null;
  }
};
export default useUserDetails;

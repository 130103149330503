import React, { useState } from "react";
import { Row, Col, Card } from "antd";
import imqge from "../assets/images/image.png";
import ResumeForm from "../components/AcrotrendTemplate";
import FormAndTemplate from "../components/FormAndTemplate";
import EditorTemplate from "../components/EditorTemplate";
import TemplateForm from "../components/templateForm1";
import NeerajTemplate from "../components/NeerajTemplate";

const TemplateSelection = () => {
  const pictures = [imqge, imqge, imqge, imqge, imqge];
  const [click, setClick] = useState(0);

  const handlePictureClick = (index) => {
    setClick(index);
  };

  return (
    <Card
      title="Template Selection"
      bordered={true}
      style={{
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div style={{ padding: "20px" }}>
        <Row gutter={[16, 16]} justify="center" align="middle">
          {pictures.map((src, index) => (
            <Col key={index} span={4}>
              <div
                style={{
                  position: "relative",
                  height: "240px",
                  overflow: "hidden",
                  cursor: "pointer",
                  border:
                    click === index ? "2px solid #3699FF" : "1px solid #ddd",
                  borderRadius: "8px",
                  boxShadow:
                    click === index ? "0 4px 8px rgba(0, 0, 0, 0.2)" : "none",
                  transition: "0.3s all ease-in-out",
                }}
                onClick={() => handlePictureClick(index)}
              >
                <img
                  src={src}
                  alt={`Picture ${index}`}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    transition: "transform 0.5s ease, box-shadow 0.5s ease",
                    transform: "scale(1) rotate(0deg)",
                  }}
                  className="enhanced-hover-effect"
                />
              </div>
            </Col>
          ))}
        </Row>
        <div
          style={{
            padding: "20px",
            borderRadius: "8px",
          }}
        >
          {click === 0 && <ResumeForm />}
          {click === 1 && <FormAndTemplate />}
          {click === 2 && <TemplateForm />}
          {click === 3 && <NeerajTemplate />}
          {click === 4 && <EditorTemplate />}
        </div>
      </div>
    </Card>
  );
};

export default TemplateSelection;

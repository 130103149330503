import React, { useEffect, useState } from "react";
import {
  Card,
  Descriptions,
  Typography,
  Divider,
  Tag,
  Image,
  Space,
  Button,
} from "antd";
import { FileOutlined, SelectOutlined } from "@ant-design/icons";
import { postReq } from "../../api";
import { useHistory } from "react-router-dom";
import { toastNotification } from "../../utils/toasthelper";

const { Title } = Typography;

const VendorProfile = () => {
  const [profileData, setProfileData] = useState({});
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleFunction = async () => {
    try {
      setLoading(true);
      const response = await postReq("/vendor/profile", {});
      if (response.status === 1) {
        setProfileData(response.data);
        setLoading(false);
      } else {
        setLoading(false);
        history.push("/vendor/requirement");
      }
    } catch (error) {
      setLoading(false);
      history.push("/vendor/requirement");
      toastNotification("error", error.message);
    }
  };
  useEffect(() => {
    handleFunction();
  }, []);
  return (
    <Card
      loading={loading}
      style={{
        width: "100%",
        margin: "0px auto",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: "10px",
        overflow: "hidden",
      }}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        {/* Title Section */}
        <Space
          align="center"
          style={{ justifyContent: "space-between", display: "flex" }}
        >
          <Title level={4} style={{ marginBottom: 0 }}>
            Vendor Profile
          </Title>
          <Image
            src={`https://rmtcsdata.blob.core.windows.net/rmt/${profileData.organizationLogo}`}
            alt="Organization Logo"
            style={{
              width: 60,
              height: 60,
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
        </Space>
        <Divider />

        {/* Profile Details */}
        <Descriptions
          column={2}
          bordered
          size="middle"
          title="Basic Information"
        >
          <Descriptions.Item label="Vendor Name">
            {profileData.name}
          </Descriptions.Item>
          <Descriptions.Item label="Vendor Email">
            <a href={`mailto:${profileData.vendorEmail}`}>
              {profileData.vendorEmail}
            </a>
          </Descriptions.Item>
          <Descriptions.Item label="Company Name">
            {profileData.vendorCompanyName}
          </Descriptions.Item>
          <Descriptions.Item label="Company Strength">
            {profileData.vendorCompanyStrength}
          </Descriptions.Item>
          <Descriptions.Item label="Website">
            <a
              href={`https://${profileData.vendorCompanyWebsiteUrl}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {profileData.vendorCompanyWebsiteUrl}
            </a>
          </Descriptions.Item>
          <Descriptions.Item label="SPOC Email">
            <a href={`mailto:${profileData.communicationEmail}`}>
              {profileData.communicationEmail}
            </a>
          </Descriptions.Item>
          <Descriptions.Item label="SPOC Phone No.">
            {profileData.communicationPhone}
          </Descriptions.Item>
          <Descriptions.Item label="SPOC Designation">
            {profileData.communicationDesignation}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions column={2} bordered size="middle" title="Legal Details">
          <Descriptions.Item label="CIN No.">
            {profileData.cinNO}
          </Descriptions.Item>
          <Descriptions.Item label="TAN No.">
            {profileData.tanNo}
          </Descriptions.Item>
          <Descriptions.Item label="CEO PAN No.">
            {profileData.ceoPanNo}
          </Descriptions.Item>
          <Descriptions.Item label="CEO Aadhar No.">
            {profileData.ceoAdharNo}
          </Descriptions.Item>
          <Descriptions.Item label="GST No.">
            {profileData.vendorCompanyGstNo}
          </Descriptions.Item>
          <Descriptions.Item label="ISO Certification">
            {profileData.isoNo}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions column={1} bordered size="middle" title="Addresses">
          <Descriptions.Item label="Registered Address">
            {profileData.RegAddress}
          </Descriptions.Item>
          <Descriptions.Item label="Operational Address">
            {profileData.operationalAddress}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions
          column={2}
          bordered
          size="middle"
          title="Additional Details"
        >
          <Descriptions.Item label="MSME No.">
            {profileData.msmeNo}
          </Descriptions.Item>
          <Descriptions.Item label="ITR No. 1">
            {profileData.itrNo1}
          </Descriptions.Item>
          <Descriptions.Item label="ITR No. 2">
            {profileData.itrNo2}
          </Descriptions.Item>
          <Descriptions.Item label="ITR No. 3">
            {profileData.itrNo3}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions
          column={2}
          bordered
          size="middle"
          title="Documents & Verification"
        >
          <Descriptions.Item label="GST Statement">
            <a
              href={`/documents/${profileData.gstStatementFile}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FileOutlined /> {profileData.gstStatementFile}
            </a>
          </Descriptions.Item>
          <Descriptions.Item label="NDA Status">
            <Tag color={profileData.isSatisfiedWithNda ? "green" : "red"}>
              {profileData.isSatisfiedWithNda ? "Satisfied" : "Not Satisfied"}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="MSA Status">
            <Tag color={profileData.isSatisfiedWithMsa ? "green" : "red"}>
              {profileData.isSatisfiedWithMsa ? "Satisfied" : "Not Satisfied"}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Account Verified">
            <Tag color={profileData.isAccountVerified ? "green" : "red"}>
              {profileData.isAccountVerified ? "Verified" : "Not Verified"}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Vendor Verified">
            <Tag color={profileData.isVendorVerified ? "green" : "red"}>
              {profileData.isVendorVerified ? "Verified" : "Not Verified"}
            </Tag>
          </Descriptions.Item>
        </Descriptions>
        <Divider />

        {/* Edit Profile Button */}
        <div style={{ textAlign: "center" }}>
          <Button
            type="primary"
            style={{
              width: "200px",
              backgroundColor: "#1890ff",
              borderRadius: "5px",
              fontWeight: "bold",
            }}
            onClick={() =>
              history.push("/vendor/Profile-update", { data: profileData })
            }
          >
            Edit Profile
          </Button>
        </div>
      </Space>
    </Card>
  );
};

export default VendorProfile;

import React, { useState, useEffect } from "react";
import { Form, Input, Button } from "antd";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import { postReq } from "../api";

const ResumeForm = () => {
  const [skillEditor, setSkillEditor] = useState(null);
  const [certEditor, setCertEditor] = useState(null);
  const [expEditor, setExpEditor] = useState(null);

  const handleFinish = (values) => {
    const skills = JSON.stringify(skillEditor.root.innerHTML);
    const certifications = JSON.stringify(certEditor.root.innerHTML);
    const experience = JSON.stringify(expEditor.root.innerHTML);

    // Send data to the backend
    const payload = {
      ...values,
      skills,
      certifications,
      experience,
    };

    const response = postReq("/vendor/generate_resume", {
      payload,
      template: "4",
    });
  };

  useEffect(() => {
    // Initialize Quill editors
    setSkillEditor(
      new Quill("#skills-editor", {
        theme: "snow",
      })
    );
    setCertEditor(
      new Quill("#certifications-editor", {
        theme: "snow",
      })
    );
    setExpEditor(
      new Quill("#experience-editor", {
        theme: "snow",
      })
    );
  }, []);

  return (
    <div style={{ margin: "20px auto" }}>
      <h1 style={{ textAlign: "center" }}>Resume Form</h1>
      <Form layout="vertical" onFinish={handleFinish}>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please input your name!" }]}
        >
          <Input placeholder="Enter your name" />
        </Form.Item>

        <Form.Item
          label="Summary"
          name="summary"
          rules={[{ required: true, message: "Please enter a summary!" }]}
        >
          <Input.TextArea placeholder="Enter a brief summary" rows={4} />
        </Form.Item>

        <Form.Item label="Skills">
          <div
            id="skills-editor"
            style={{
              border: "1px solid #d9d9d9",
              borderRadius: "6px",
              minHeight: "150px",
            }}
          ></div>
        </Form.Item>

        <Form.Item label="Certifications">
          <div
            id="certifications-editor"
            style={{
              border: "1px solid #d9d9d9",
              borderRadius: "6px",
              minHeight: "150px",
            }}
          ></div>
        </Form.Item>

        <Form.Item label="Experience">
          <div
            id="experience-editor"
            style={{
              border: "1px solid #d9d9d9",
              borderRadius: "6px",
              minHeight: "150px",
            }}
          ></div>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ResumeForm;

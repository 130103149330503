import React, { useState } from "react";
import { Form, Input, Button, message, Space } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { postReq } from "../api";

const ResumeForm = () => {
  const [form] = Form.useForm();
  const [summary, setSummary] = useState("");
  const [skills, setSkills] = useState("");

  const defaultProjects = [
    {
      projectName: "Sample Project",
      roles: "<p>Initial roles for the sample project</p>",
    },
  ];

  const onFinish = async (values) => {
    try {
      const formattedProjects = values.projects.map((project) => ({
        projectName: project.projectName,
        role: project.role,
        responsibilities: JSON.stringify(project.responsibilities),
      }));

      const finalValues = {
        ...values,
        summary: JSON.stringify(summary),
        summaryskillset: JSON.stringify(skills),
        projects: JSON.stringify(formattedProjects),
      };

      const response = await postReq("/vendor/generate_resume", {
        finalValues,
        template: "3",
      });

      if (response.ok) {
        message.success("Form submitted successfully!");
      } else {
        message.error("Failed to submit the form!");
      }
    } catch (error) {
      message.error("An error occurred while submitting the form!");
      console.error(error);
    }
  };

  return (
    <div
      style={{
        margin: "20px auto",
        padding: 20,
        background: "#fff",
        borderRadius: 8,
        boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
      }}
    >
      <h2 style={{ textAlign: "center", marginBottom: 20 }}>Resume Form</h2>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          projects: defaultProjects,
        }}
      >
        {/* Candidate Name */}
        <Form.Item
          label="Candidate Name"
          name="name"
          rules={[{ required: true, message: "Candidate name is required" }]}
        >
          <Input placeholder="Enter the Candidate name" maxLength={20} />
        </Form.Item>

        {/* Profile Highlights and Skills Side by Side */}
        <Space
          style={{ display: "flex", justifyContent: "space-between" }}
          align="start"
        >
          <Form.Item
            label="Profile Highlights"
            name="summary"
            style={{ flex: 1, maxWidth: "500px" }}
            rules={[
              { required: true, message: "Profile highlights are required" },
            ]}
          >
            <ReactQuill
              theme="snow"
              value={summary}
              onChange={setSummary}
              placeholder="Write a brief profile summary"
            />
          </Form.Item>
          <Form.Item
            label="Skills"
            name="summaryskillset"
            style={{ flex: 1, maxWidth: "500px" }}
            rules={[{ required: true, message: "Skills are required" }]}
          >
            <ReactQuill
              theme="snow"
              value={skills}
              onChange={setSkills}
              placeholder="List your skills separated by commas"
            />
          </Form.Item>
        </Space>

        {/* Projects Section */}
        <Form.List name="projects">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <Space
                  key={key}
                  direction="vertical"
                  style={{
                    display: "flex",
                    marginBottom: 20,
                    border: "1px solid #d9d9d9",
                    padding: 15,
                    borderRadius: 8,
                  }}
                >
                  {/* Project Name and Role Side by Side */}
                  <Space style={{ display: "flex" }} align="start">
                    <Form.Item
                      {...restField}
                      label="Project Name"
                      name={[name, "projectName"]}
                      fieldKey={[fieldKey, "projectName"]}
                      style={{ flex: 1 }}
                      rules={[
                        {
                          required: true,
                          message: "Please enter the project name",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter the project name"
                        maxLength={20}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label="Role"
                      name={[name, "role"]}
                      fieldKey={[fieldKey, "role"]}
                      style={{ flex: 1 }}
                      rules={[
                        {
                          required: true,
                          message: "Please specify the role",
                        },
                      ]}
                    >
                      <Input placeholder="Enter the role for this project" />
                    </Form.Item>
                  </Space>

                  {/* Responsibilities */}
                  <Form.Item
                    {...restField}
                    label="Responsibilities"
                    name={[name, "responsibilities"]}
                    fieldKey={[fieldKey, "responsibilities"]}
                    rules={[
                      {
                        required: true,
                        message: "Please describe the responsibilities",
                      },
                    ]}
                  >
                    <ReactQuill
                      theme="snow"
                      placeholder="Describe the responsibilities"
                    />
                  </Form.Item>

                  {/* Remove Project Button */}
                  <Button
                    type="dashed"
                    icon={<DeleteOutlined />}
                    onClick={() => remove(name)}
                    danger
                    style={{ color: "red" }}
                  >
                    Delete
                  </Button>
                </Space>
              ))}

              {/* Add Project and Submit Buttons */}
              <Form.Item>
                <div style={{ display: "flex", gap: "10px" }}>
                  <Button
                    type="dashed"
                    icon={<PlusOutlined />}
                    onClick={() => add()}
                  >
                    Add Project
                  </Button>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </div>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </div>
  );
};

export default ResumeForm;

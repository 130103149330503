import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import BasePage from "./BasePage";
import { CompanyRoutesConfig } from "./companyRoutesConfig";
import useUserDetails from "./utils/useUserDetails";
import Main from "./components/layout/Main";
import NewSignIn from "./pages/newSignIn";

export function Routes() {
  const { userRole, permissions } = useUserDetails();
  const companyToken = localStorage.getItem("companyToken");
  const vendorToken = localStorage.getItem("vendorToken");
  const userToken = localStorage.getItem("userToken");
  const isAuthorized = companyToken || vendorToken || userToken;
  const isCompany = userRole === "company";
  const isVendor = userRole === "vendor";

  return (
    <Switch>
      {!isAuthorized ? (
        <Route>
          <NewSignIn />
        </Route>
      ) : isCompany ? (
        <Redirect from="auth/company/login" to="/company/dashboard" />
      ) : isVendor ? (
        <Redirect from="/auth/vendor/login" to="/vendor/requirement" />
      ) : (
        <Redirect from="/auth/vendor/login" to="/company/dashboard" />
      )}
      {!isAuthorized ? (
        <Redirect to="/auth/vendor/login" />
      ) : (
        <Main>
          <BasePage
            userRole={userRole}
            permissions={permissions}
            CompanyRoutesConfig={CompanyRoutesConfig}
          />
        </Main>
      )}
    </Switch>
  );
}

import React, { useEffect, useState } from "react";
import { Card, Descriptions, Typography, Spin, Button, Row, Col } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { postReq } from "../api";
import { toastNotification } from "../utils/toasthelper";

const { Title } = Typography;

const CandidateDetails = () => {
  const [loading, setLoading] = useState(false);
  const [candidateDetails, setCandidateDetails] = useState({});
  const [candidateOnBoard, setCandidateOnBoard] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const data = location.state.record.record;
  const userRole = localStorage.getItem("userRole");

  useEffect(() => {
    const fetchCandidateDetails = async () => {
      if (data.candidateMatch[0]) {
        setCandidateOnBoard(true);
        try {
          setLoading(true);
          let response = {};
          if (userRole == "company") {
            response = await postReq(
              `/company/candidate-details/${data.candidateMatch[0]._id}`
            );
          } else if (userRole !== "vendor" || userRole !== "candidate") {
            response = await postReq(
              `/common/candidate-details-user/${data.candidateMatch[0]._id}`
            );
          }
          if (response.status === 1) {
            toastNotification(
              "success",
              response.message || "Fetched successfully"
            );
            setCandidateDetails(response.data);
            setLoading(false);
          } else {
            setLoading(false);
            toastNotification(
              "error",
              response.message || "Error fetching details"
            );
          }
        } catch (error) {
          toastNotification("error", error.message || "Error fetching details");
        }
      } else {
        setCandidateDetails({
          name: "",
          candidatePanCard: "",
          candidateAdharCard: "",
          candidateContactNo: "",
          candidateAddress: "",
          candidateEmail: "",
          paymentType: "",
          amountPerType: "",
          candidateJoiningDate: "",
          vendorDetails: {
            name: data.vendorDetails.name,
            communicationEmail: data.vendorDetails.communicationEmail,
            companyWebsite: data.vendorDetails.vendorCompanyWebsiteUrl,
          },
          resumeLink: data.resumeLink,
        });
        setCandidateOnBoard(data.isCandidateOnBoardFound);
      }
    };

    fetchCandidateDetails();
  }, [data]);

  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: "100px" }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <Card
      style={{ margin: "20px auto", borderRadius: "8px" }}
      bodyStyle={{ padding: "24px" }}
    >
      <Title level={3} style={{ textAlign: "left", marginBottom: "20px" }}>
        Candidate Details
        {!candidateOnBoard && (
          <svg
            width="44"
            height="44"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ cursor: "pointer" }}
            onClick={() =>
              history.push("/company/Candidate/create", {
                record: {
                  appliedRequirementId: data.appliedRequirementId,
                  vendorId: data.vendorDetails._id,
                  finalBudget: data.finalBudget,
                },
              })
            }
          >
            <rect width="64" height="64" rx="18" fill="#F4F4F4" />
            <path
              d="M32 22V42"
              stroke="#13487D"
              stroke-width="2.7"
              stroke-linecap="round"
            />
            <path
              d="M42 32L22 32"
              stroke="#13487D"
              stroke-width="2.7"
              stroke-linecap="round"
            />
          </svg>
        )}
      </Title>
      <Row gutter={[16, 16]}>
        {/* Left Column */}
        <Col xs={24} sm={12}>
          <Descriptions bordered column={1} size="middle">
            <Descriptions.Item label="Name">
              {candidateDetails.name}
            </Descriptions.Item>
            <Descriptions.Item label="Email">
              {candidateDetails.candidateEmail}
            </Descriptions.Item>
            <Descriptions.Item label="Contact No">
              {candidateDetails.candidateContactNo}
            </Descriptions.Item>
            <Descriptions.Item label="Address">
              {candidateDetails.candidateAddress}
            </Descriptions.Item>
            <Descriptions.Item label="PAN Card">
              <a
                href={candidateDetails.candidatePanCard}
                target="_blank"
                rel="noopener noreferrer"
              >
                View PAN Card
              </a>
            </Descriptions.Item>
            <Descriptions.Item label="Aadhar Card">
              <a
                href={candidateDetails.candidateAdharCard}
                target="_blank"
                rel="noopener noreferrer"
              >
                View Aadhar Card
              </a>
            </Descriptions.Item>
            <Descriptions.Item label="Joining Date">
              {candidateDetails.candidateJoiningDate}
            </Descriptions.Item>
          </Descriptions>
        </Col>

        {/* Right Column */}
        <Col xs={24} sm={12}>
          <Descriptions bordered column={1} size="middle">
            <Descriptions.Item label="Payment Type">
              {candidateDetails.paymentType}
            </Descriptions.Item>
            <Descriptions.Item label="Amount per Type">
              ₹{candidateDetails.amountPerType}
            </Descriptions.Item>
            <Descriptions.Item label="Vendor Name">
              {candidateDetails.vendorDetails?.name}
            </Descriptions.Item>
            <Descriptions.Item label="Communication Email">
              {candidateDetails.vendorDetails?.communicationEmail}
            </Descriptions.Item>
            <Descriptions.Item label="Vendor Website">
              <a
                href={`https://${candidateDetails.vendorDetails?.companyWebsite}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {candidateDetails.vendorDetails?.companyWebsite}
              </a>
            </Descriptions.Item>
            <Descriptions.Item label="Resume">
              <a
                href={`https://${process.env.REACT_APP_BUCKET_URL}${candidateDetails.resumeLink}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button type="link">View Resume</Button>
              </a>
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </Card>
  );
};

export default CandidateDetails;

import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { postReq } from "../../api";

// Register necessary components for Chart.js v4
ChartJS.register(ArcElement, Tooltip, Legend);

const VendorAnalytics = ({ onTotalsUpdate }) => {
  const [chartData, setChartData] = useState(null);
  const [profileChartData, setProfileChartData] = useState(null);
  const [totalShortlisted, setTotalShortlisted] = useState(0);
  const [totalSelected, setTotalSelected] = useState(0);
  const [totalApplied, setTotalApplied] = useState(0);

  useEffect(async () => {
    const response = await postReq("/vendor/vendor_analytics", {});

    const statusCounts = {
      pending: 0,
      shortlisted: 0,
      rejected: 0,
      onhold: 0,
    };

    const profileStatusConunt = {
      inprocess: 0,
      selected: 0,
      rejected: 0,
      onhold: 0,
    };

    setTotalApplied(response?.data.length);

    response.data.forEach((item) => {
      if (statusCounts.hasOwnProperty(item.cvScreeningStatus)) {
        statusCounts[item.cvScreeningStatus]++;
      }
      if (profileStatusConunt.hasOwnProperty(item?.profileStatus)) {
        profileStatusConunt[item.profileStatus]++;
      }
    });
    setProfileChartData({
      labels: ["Inprocess", "Selected", "Rejected", "On Hold"],
      datasets: [
        {
          data: [
            profileStatusConunt.inprocess,
            profileStatusConunt.selected,
            profileStatusConunt.rejected,
            profileStatusConunt.onhold,
          ],
          backgroundColor: ["#FFCE56", "#36A2EB", "#FF6384", "#FF9F40"], // Colors for slices
          hoverOffset: 4,
        },
      ],
    });
    setChartData({
      labels: ["Pending", "Shortlisted", "Rejected", "On Hold"],
      datasets: [
        {
          data: [
            statusCounts.pending,
            statusCounts.shortlisted,
            statusCounts.rejected,
            statusCounts.onhold,
          ],
          backgroundColor: ["#FFCE56", "#36A2EB", "#FF6384", "#FF9F40"], // Colors for slices
          hoverOffset: 4,
        },
      ],
    });
    setTotalShortlisted(statusCounts.shortlisted);
    setTotalSelected(profileStatusConunt.selected);
    if (onTotalsUpdate) {
      onTotalsUpdate(profileStatusConunt.selected, response?.data.length);
    }
  }, []);

  return (
    <>
      <div style={{ display: "flex" }}>
        <div style={{ width: "250px", height: "250px", margin: "auto" }}>
          {chartData ? <Pie data={chartData} /> : <p>Loading Chart...</p>}
          <p style={{ textAlign: "center", marginTop: "10px" }}>
            Total Shortlisted CVs:{" "}
            <strong>{`${totalShortlisted}/${totalApplied}`}</strong>
          </p>
        </div>
        <div style={{ width: "250px", height: "250px", margin: "auto" }}>
          {profileChartData ? (
            <Pie data={profileChartData} />
          ) : (
            <p>Loading Chart...</p>
          )}
          <p style={{ textAlign: "center", marginTop: "10px" }}>
            Total Selected Profiles:{" "}
            <strong>{`${totalSelected}/${totalShortlisted}`}</strong>
          </p>
        </div>
      </div>
      <p style={{ textAlign: "center", marginTop: "50px" }}>
        Total success ratio:{" "}
        <strong>{`${totalSelected}/${totalApplied}`}</strong>
      </p>
    </>
  );
};

export default VendorAnalytics;

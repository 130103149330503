import React from "react";
import { Form, Input, Row, Col, Button, Divider, Card } from "antd";
import { postReq } from "../api";

const TemplateForm = () => {
  const onFinish = async (values) => {
    const payload = {
      data: values, // Form data
      template: "0", // Template ID
    };
    const response = await postReq("/vendor/generate_resume", payload);
  };

  return (
    <div>
      <Card
        title="Candidate Details"
        bordered={false}
        style={{ margin: "0 auto" }}
      >
        <Form layout="vertical" onFinish={onFinish}>
          {/* Candidate Name & Total Experience */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Candidate Name"
                name="candidateName"
                rules={[{ required: true, message: "Please enter the name!" }]}
              >
                <Input placeholder="Enter candidate name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Total Experience"
                name="totalExperience"
                rules={[
                  {
                    required: true,
                    message: "Please enter the total experience!",
                  },
                ]}
              >
                <Input placeholder="Enter total experience in years" />
              </Form.Item>
            </Col>
          </Row>

          {/* Qualifications as an Array */}
          <Divider>Qualifications</Divider>
          <Form.List name="qualifications">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Row key={key} gutter={16} style={{ marginBottom: "10px" }}>
                    <Col span={20}>
                      <Form.Item
                        {...restField}
                        name={name}
                        fieldKey={fieldKey}
                        rules={[
                          {
                            required: true,
                            message: "Please enter a qualification!",
                          },
                        ]}
                      >
                        <Input placeholder="Enter a qualification" />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Button danger onClick={() => remove(name)}>
                        Remove
                      </Button>
                    </Col>
                  </Row>
                ))}
                <Button type="dashed" onClick={() => add()} block>
                  Add Qualification
                </Button>
              </>
            )}
          </Form.List>

          {/* Skills as an Array */}
          <Divider>Skills</Divider>
          <Form.List name="skills">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Row key={key} gutter={16} style={{ marginBottom: "10px" }}>
                    <Col span={20}>
                      <Form.Item
                        {...restField}
                        name={name}
                        fieldKey={fieldKey}
                        rules={[
                          {
                            required: true,
                            message: "Please enter a skill!",
                          },
                        ]}
                      >
                        <Input placeholder="Enter a skill" />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Button danger onClick={() => remove(name)}>
                        Remove
                      </Button>
                    </Col>
                  </Row>
                ))}
                <Button type="dashed" onClick={() => add()} block>
                  Add Skill
                </Button>
              </>
            )}
          </Form.List>

          {/* Education */}
          <Divider>Education</Divider>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="University"
                name={["education", "university"]}
                rules={[
                  { required: true, message: "Please enter the university!" },
                ]}
              >
                <Input placeholder="Enter university name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Graduation Year"
                name={["education", "year"]}
                rules={[
                  {
                    required: true,
                    message: "Please enter the graduation year!",
                  },
                ]}
              >
                <Input placeholder="Enter graduation year" />
              </Form.Item>
            </Col>
          </Row>

          {/* Projects */}
          <Divider>Projects</Divider>
          <Form.List name="projects">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <div key={key} style={{ marginBottom: "20px" }}>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          {...restField}
                          label="Project Name"
                          name={[name, "name"]}
                          fieldKey={[fieldKey, "name"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter project name",
                            },
                          ]}
                        >
                          <Input placeholder="Enter project name" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          {...restField}
                          label="Duration"
                          name={[name, "duration"]}
                          fieldKey={[fieldKey, "duration"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter duration",
                            },
                          ]}
                        >
                          <Input placeholder="Enter project duration" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item
                      {...restField}
                      label="Description"
                      name={[name, "description"]}
                      fieldKey={[fieldKey, "description"]}
                      rules={[
                        { required: true, message: "Please enter description" },
                      ]}
                    >
                      <Input.TextArea
                        placeholder="Enter project description"
                        rows={4}
                        allowClear
                      />
                    </Form.Item>

                    {/* Technologies as an Array in Projects */}
                    <Form.List name={[name, "technologies"]}>
                      {(
                        technologyFields,
                        { add: addTechnology, remove: removeTechnology }
                      ) => (
                        <>
                          {technologyFields.map(
                            ({ key, name, fieldKey, ...restField }) => (
                              <Row
                                key={key}
                                gutter={16}
                                style={{ marginBottom: "10px" }}
                              >
                                <Col span={20}>
                                  <Form.Item
                                    {...restField}
                                    name={name}
                                    fieldKey={fieldKey}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please enter a technology!",
                                      },
                                    ]}
                                  >
                                    <Input placeholder="Enter a technology" />
                                  </Form.Item>
                                </Col>
                                <Col span={4}>
                                  <Button
                                    danger
                                    onClick={() => removeTechnology(name)}
                                  >
                                    Remove Technology
                                  </Button>
                                </Col>
                              </Row>
                            )
                          )}
                          <Button
                            type="dashed"
                            onClick={() => addTechnology()}
                            block
                            style={{ marginBottom: "10px" }}
                          >
                            Add Technology
                          </Button>
                        </>
                      )}
                    </Form.List>

                    {/* Roles as an Array in Projects */}
                    <Form.List name={[name, "roles"]}>
                      {(roleFields, { add: addRole, remove: removeRole }) => (
                        <>
                          {roleFields.map(
                            ({ key, name, fieldKey, ...restField }) => (
                              <Row
                                key={key}
                                gutter={16}
                                style={{ marginBottom: "10px" }}
                              >
                                <Col span={20}>
                                  <Form.Item
                                    {...restField}
                                    name={name}
                                    fieldKey={fieldKey}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please enter a role!",
                                      },
                                    ]}
                                  >
                                    <Input placeholder="Enter a role" />
                                  </Form.Item>
                                </Col>
                                <Col span={4}>
                                  <Button
                                    danger
                                    onClick={() => removeRole(name)}
                                  >
                                    Remove Role
                                  </Button>
                                </Col>
                              </Row>
                            )
                          )}
                          <Button
                            type="dashed"
                            style={{ marginBottom: "10px" }}
                            onClick={() => addRole()}
                            block
                          >
                            Add Role
                          </Button>
                        </>
                      )}
                    </Form.List>

                    <Button
                      danger
                      onClick={() => remove(name)}
                      style={{ marginBottom: "10px" }}
                    >
                      Remove Project
                    </Button>
                  </div>
                ))}
                <Button type="dashed" onClick={() => add()}>
                  Add Project
                </Button>
              </>
            )}
          </Form.List>

          {/* Submit Button */}
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default TemplateForm;

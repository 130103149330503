import ReactApexChart from "react-apexcharts";
import { Typography } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import lineChart from "./configs/lineChart";
import VendorAnalytics from "../../pages/Vendor/vendorAnalytics";
import { useEffect, useState } from "react";

function LineChart() {
  const [percent, setPercent] = useState(0);
  const { Title, Paragraph } = Typography;
  const handleTotalsUpdate = (selected, total) => {
    setPercent(((selected / total) * 100).toFixed(2));
  };
  return (
    <>
      <div className="linechart">
        <div>
          <Title level={5}>Active Users</Title>
          <Paragraph className="lastweek">
            success Ratio <span className="bnb2">{percent}%</span>
          </Paragraph>
        </div>
      </div>

      <VendorAnalytics onTotalsUpdate={handleTotalsUpdate} />
    </>
  );
}

export default LineChart;

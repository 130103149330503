import React, { useState } from "react";
import { Form, Input, Row, Col, Button, Divider, Card } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import { postReq } from "../api";

const FormAndTemplate = () => {
  const [skillsContent, setSkillsContent] = useState(""); // State to handle skills editor content
  const [qualificationsContent, setQualificationsContent] = useState(""); // State to handle qualifications editor content
  const [projectsCotent, setProjectContent] = useState("");

  const onFinish = async (values) => {
    const payload = {
      data: {
        ...values,
      },
      htmlContent: JSON.stringify({
        skills: skillsContent,
        qualifications: qualificationsContent,
        projects: projectsCotent,
      }),
      template: "0",
    };
    const response = await postReq(
      "/vendor/generate_resume_editor_form",
      payload
    );
  };

  const handleSkillsChange = (value) => {
    setSkillsContent(value); // Update skills editor state
  };

  const handleQualificationsChange = (value) => {
    setQualificationsContent(value); // Update qualifications editor state
  };

  const handleProjectChange = (value) => {
    setProjectContent(value);
  };

  const modules = {
    toolbar: [
      [{ font: [] }, { size: [] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [{ direction: "rtl" }, { align: [] }],
      ["link", "image", "video", "formula"],
      ["clean"],
    ],
  };

  const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "script",
    "header",
    "blockquote",
    "code-block",
    "list",
    "bullet",
    "indent",
    "direction",
    "align",
    "link",
    "image",
    "video",
    "formula",
  ];

  return (
    <div>
      <Card
        title="Candidate Details"
        bordered={false}
        style={{ margin: "0 auto" }}
      >
        <Form layout="vertical" onFinish={onFinish}>
          {/* Candidate Name & Total Experience */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Candidate Name"
                name="candidateName"
                rules={[{ required: true, message: "Please enter the name!" }]}
              >
                <Input placeholder="Enter candidate name" maxLength={20} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Total Experience"
                name="totalExperience"
                rules={[
                  {
                    required: true,
                    message: "Please enter the total experience!",
                  },
                ]}
              >
                <Input
                  placeholder="Enter total experience in years"
                  maxLength={2}
                />
              </Form.Item>
            </Col>
          </Row>
          {/* Qualifications with Editor */}
          <Divider />
          <Form.Item
            label="Qualifications (use bullet points)"
            required
            rules={[
              {
                validator: () => {
                  if (qualificationsContent.trim() === "") {
                    return Promise.reject(
                      "Please enter at least one qualification!"
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <ReactQuill
              value={qualificationsContent}
              onChange={handleQualificationsChange}
              placeholder="Enter qualifications as bullet points"
              modules={modules}
              formats={formats}
            />
          </Form.Item>
          {/* Skills with Editor */}
          <Divider />
          <Form.Item
            label="Skills (use bullet points)"
            required
            rules={[
              {
                validator: () => {
                  if (skillsContent.trim() === "") {
                    return Promise.reject("Please enter at least one skill!");
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <ReactQuill
              value={skillsContent}
              onChange={handleSkillsChange}
              placeholder="Enter skills as bullet points"
              modules={modules}
              formats={formats}
            />
          </Form.Item>
          <Divider />
          <Form.Item
            label="Projects (customize using editor)"
            required
            rules={[
              {
                validator: () => {
                  if (projectsCotent.trim() === "") {
                    return Promise.reject(
                      "Please enter at least one Project with Project name, Duration, Description, Technology used!"
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <ReactQuill
              value={projectsCotent}
              onChange={handleProjectChange}
              placeholder="Enter at least one Project with Project name, Duration, Description, Technology used!"
              modules={modules}
              formats={formats}
            />
          </Form.Item>
          {/* Education */}
          <Divider>Education</Divider>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="University"
                name={["education", "university"]}
                rules={[
                  { required: true, message: "Please enter the university!" },
                ]}
              >
                <Input placeholder="Enter university name" maxLength={20} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Graduation Year"
                name={["education", "year"]}
                rules={[
                  {
                    required: true,
                    message: "Please enter the graduation year!",
                  },
                ]}
              >
                <Input placeholder="Enter graduation year" maxLength={4} />
              </Form.Item>
            </Col>
          </Row>
          {/* Submit Button */}
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default FormAndTemplate;

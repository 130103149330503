import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  DatePicker,
  Button,
  Card,
  Typography,
  Row,
  Col,
  TimePicker,
  message,
} from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { postReq } from "../api";
import moment from "moment";
import { toastNotification } from "../utils/toasthelper";
import { useHistory } from "react-router-dom";

const { Title } = Typography;

const EditTaskForm = (record) => {
  const history = useHistory();
  const editData = record.location.state.data;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState([
    { id: 1, taskName: "", taskDesc: "", startTime: null, endTime: null },
  ]);
  const [rejectionMessage, setRejectionMessage] = useState([]);
  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        date: editData.date ? moment(editData.date) : null,
        projectName: editData.projectName,
      });
      setTasks(
        editData.tasks.map((task, index) => ({
          id: index + 1,
          taskName: task.taskName || "",
          taskDesc: task.taskDesc || "",
          startTime: task.startTime ? moment(task.startTime) : null,
          endTime: task.endTime ? moment(task.endTime) : null,
        }))
      );
      setRejectionMessage(editData.rejectionMessage || []);
    }
  }, [editData, form]);
  const apiDateMoment = moment(editData.date);
  const startOfWeek = moment().startOf("week");
  const endOfWeek = moment().endOf("week");

  const isApiDateInCurrentWeek =
    apiDateMoment.isSameOrAfter(startOfWeek, "day") &&
    apiDateMoment.isSameOrBefore(endOfWeek, "day");
  const handleAddTask = () => {
    setTasks([
      ...tasks,
      {
        id: tasks.length + 1,
        taskName: "",
        taskDesc: "",
        startTime: null,
        endTime: null,
      },
    ]);
  };

  const handleRemoveTask = (id) => {
    if (tasks.length === 1) {
      message.warning("At least one task must be present.");
      return;
    }
    setTasks(tasks.filter((task) => task.id !== id));
  };

  const handleFinish = async (values) => {
    setLoading(true);
    try {
      const payload = {
        _id: editData.parentId,
        date: values.date ? values.date.toISOString() : null,
        projectName: values.projectName,
        approvalStatus: "pending",
        tasks: tasks.map((task) => ({
          taskName: task.taskName,
          taskDesc: task.taskDesc,
          startTime: task.startTime ? task.startTime.toISOString() : null,
          endTime: task.endTime ? task.endTime.toISOString() : null,
        })),
      };
      const response = await postReq("/company/update-timesheet", payload);
      if (response.status == 1) {
        toastNotification("success", response.message);
        setLoading(false);
        history.push("/company/TimeSheet");
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleTaskChange = (id, key, value) => {
    const updatedTasks = tasks.map((task, index) => {
      if (task.id === id) {
        if (key === "startTime" && index > 0) {
          const prevTask = tasks[index - 1];
          if (
            prevTask.endTime &&
            moment(value).isBefore(moment(prevTask.endTime))
          ) {
            message.error(
              `Start time of Task ${
                index + 1
              } must be after the end time of Task ${index}.`
            );
            return task;
          }
        }
        if (key === "endTime" && task.startTime) {
          if (moment(value).isBefore(moment(task.startTime))) {
            message.error(
              `End time of Task ${
                index + 1
              } must not be earlier than its start time.`
            );
            return task;
          }
        }
        return { ...task, [key]: value };
      }
      return task;
    });
    setTasks(updatedTasks);
  };

  return (
    <Card
      title={
        <div>
          <Title
            level={4}
            style={{ marginBottom: 0, fontWeight: "bold", color: "#13487D" }}
          >
            Add Tasks
          </Title>
        </div>
      }
      bordered={false}
      style={{ borderRadius: "18px" }}
    >
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Select Date"
              name="date"
              rules={[{ required: true, message: "Please select a date." }]}
            >
              <DatePicker
                style={{ width: "100%" }}
                disabled={!isApiDateInCurrentWeek}
                disabledDate={(current) => {
                  const startOfWeek = moment().startOf("week");
                  const endOfWeek = moment().endOf("week");
                  const today = moment().endOf("day");

                  return (
                    !current ||
                    current < startOfWeek ||
                    current > endOfWeek ||
                    current > today // Disable future dates
                  );
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Project Name"
              name="projectName"
              rules={[
                { required: true, message: "Please enter the project name." },
              ]}
            >
              <Input placeholder="Enter Project Name" />
            </Form.Item>
          </Col>
        </Row>

        <div>
          {tasks.map((task, index) => (
            <Card
              key={task.id}
              style={{
                marginBottom: 16,
                border: "1px solid #d9d9d9",
                borderRadius: 8,
              }}
            >
              <Row gutter={16}>
                <Col span={24}>
                  <Title level={5}>Task {index + 1}</Title>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item label="Task Name" required>
                    <Input
                      placeholder="Task Name"
                      value={task.taskName}
                      onChange={(e) =>
                        handleTaskChange(task.id, "taskName", e.target.value)
                      }
                    />
                  </Form.Item>

                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="Start Time" required>
                        <TimePicker
                          format="HH:mm"
                          style={{ width: "100%" }}
                          value={task.startTime ? moment(task.startTime) : null}
                          onChange={(value) =>
                            handleTaskChange(task.id, "startTime", value)
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="End Time" required>
                        <TimePicker
                          format="HH:mm"
                          style={{ width: "100%" }}
                          value={task.endTime ? moment(task.endTime) : null}
                          onChange={(value) =>
                            handleTaskChange(task.id, "endTime", value)
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item label="Task Description" required>
                    <Input.TextArea
                      placeholder="Enter Task Description"
                      value={task.taskDesc}
                      autoSize={{ minRows: 5, maxRows: 6 }}
                      onChange={(e) =>
                        handleTaskChange(task.id, "taskDesc", e.target.value)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={24} style={{ textAlign: "right" }}>
                  <Button
                    type="danger"
                    icon={<MinusOutlined />}
                    onClick={() => handleRemoveTask(task.id)}
                  >
                    Remove Task
                  </Button>
                </Col>
              </Row>
            </Card>
          ))}
          {rejectionMessage.length > 0 &&
            rejectionMessage.map((data, i) => (
              <Form.Item
                label={`Rejection Messages ${i + 1}`}
                required
                disabled
              >
                <Input.TextArea value={data} />
              </Form.Item>
            ))}
        </div>

        <div
          style={{ display: "flex", gap: "8px", justifyContent: "flex-end" }}
        >
          <Button
            type="dashed"
            icon={<PlusOutlined />}
            onClick={handleAddTask}
            style={{ marginTop: 16 }}
          >
            Add Task
          </Button>

          <Form.Item style={{ marginTop: 16 }}>
            <Button type="primary" htmlType="submit" block loading={loading}>
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Card>
  );
};

export default EditTaskForm;

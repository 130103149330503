import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  DatePicker,
  Button,
  Upload,
  message,
  Card,
  Typography,
  Row,
  Col,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { postReq } from "../api";
import { toastNotification } from "../utils/toasthelper";
import { useLocation, useHistory } from "react-router-dom";
const { Option } = Select;
const { Title } = Typography;

const CandidateForm = ({ appliedRequirementId, vendorId, finalBudget }) => {
  const [form] = Form.useForm();
  const [panCardFile, setPanCardFile] = useState(null);
  const [aadharCardFile, setAadharCardFile] = useState(null);
  const [managers, setManagers] = useState([]);
  const [selectedManager, setSelectedManager] = useState("");
  const history = useHistory();
  const location = useLocation();
  // const { record } = location.state || {
  //   appliedRequirementId: "",
  //   vendorId: "",
  //   finalBudget: "",
  // };
  console.log(appliedRequirementId);
  const record = { appliedRequirementId, vendorId, finalBudget };
  console.log(record);
  const [loading, setLoading] = useState(false);
  const fetchManager = async (req, res) => {
    try {
      const response = await postReq("/company/get-manager-list", {});
      if (response.status === 1) {
        setManagers(response.data);
      }
    } catch (error) {
      toastNotification("error", "Error in fetching manager list");
    }
  };
  form.setFieldValue("amountPerType", record.finalBudget);
  useEffect(() => {
    fetchManager();
  }, []);

  const handleChange = (value) => {
    setSelectedManager(value);
  };

  const handleFinish = async (values) => {
    setLoading(true);
    const formData = new FormData();

    // Append text fields
    for (const key in values) {
      if (key !== "candidateJoiningDate") {
        formData.append(key, values[key]);
      } else if (values[key]) {
        formData.append(key, values[key].format("YYYY-MM-DD"));
      }
    }

    // Append file fields
    if (panCardFile) {
      formData.append("candidatePanCard", panCardFile);
    } else {
      message.error("PAN Card file is missing.");
      setLoading(false);
      return;
    }

    if (aadharCardFile) {
      formData.append("candidateAdharCard", aadharCardFile);
    } else {
      message.error("Aadhar Card file is missing.");
      setLoading(false);
      return;
    }
    formData.append("vendorId", record.vendorId);
    // formData.append("managerId", selectedManager);
    formData.append("applyRequirementId", record.appliedRequirementId);
    try {
      const response = await postReq("/company/candidate/create", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 1) {
        toastNotification("success", "Candidate created successfully!");
        const result = await postReq("company/assign-manager", {
          candidateId: record._id,
          managerId: selectedManager,
          applyRequirementId: record.appliedRequirementId,
        });
        toastNotification("success", "manager assigned successfully");
        setLoading(false);
        history.push("/company/consultants");
      } else {
        toastNotification(
          "error",
          "Failed to create candidate. Please try again."
        );
        setLoading(false);
      }
    } catch (error) {
      toastNotification(
        "error",
        "Failed to create candidate. Please try again."
      );
      setLoading(false);
    }
  };

  const handleFileChange = (info, setFile) => {
    const { file } = info;
    const isValidFile = validateFile(file);
    if (isValidFile) {
      setFile(file);
    }
  };
  const validateFile = (file) => {
    if (!file) return false;

    // Validate file type and size
    const isSmallerThan2MB = file.size / 1024 / 1024 < 2;

    if (!isSmallerThan2MB) {
      message.error("File size must be smaller than 2MB!");
      return false;
    }

    return true;
  };
  return (
    <Card
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "16px",
            borderRadius: "20px 20px 0px 0px",
          }}
        >
          <div>
            <Title
              level={4}
              style={{
                marginBottom: 0,
                fontSize: "20px",
                fontWeight: "800",
                color: "#13487D",
              }}
            >
              Candidate Details
            </Title>
          </div>
        </div>
      }
      bordered={false}
      style={{ borderRadius: "18px" }}
    >
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Candidate Name"
              name="name"
              rules={[
                { required: true, message: "Candidate name is required." },
              ]}
            >
              <Input placeholder="Enter Candidate Name" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Assign Manager"
              name="assignmanager"
              rules={[
                { required: true, message: "Assign manager to candidate." },
              ]}
            >
              <Select placeholder="Assign Manager" onChange={handleChange}>
                {managers.map((data) => (
                  <Option value={data._id}>{data.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="PAN Card"
              rules={[{ required: true, message: "PAN Card is required." }]}
            >
              <Upload
                accept=".jpg"
                maxCount={1}
                beforeUpload={() => false} // Prevent auto-upload
                onChange={(info) => handleFileChange(info, setPanCardFile)}
              >
                <Button icon={<UploadOutlined />}>Upload PAN Card</Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Aadhar Card"
              rules={[{ required: true, message: "Aadhar Card is required." }]}
            >
              <Upload
                accept=".jpg"
                maxCount={1}
                beforeUpload={() => false} // Prevent auto-upload
                onChange={(info) => handleFileChange(info, setAadharCardFile)}
              >
                <Button icon={<UploadOutlined />}>Upload Aadhar Card</Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Contact Number"
              name="candidateContactNo"
              rules={[
                { required: true, message: "Contact Number is required." },
                {
                  pattern: /^\d{10}$/,
                  message: "Enter a valid 10-digit contact number.",
                },
              ]}
            >
              <Input placeholder="Enter Contact Number" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Address"
              name="candidateAddress"
              rules={[{ required: true, message: "Address is required." }]}
            >
              <Input placeholder="Enter Address" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Email"
              name="candidateEmail"
              rules={[
                { required: true, message: "Email is required." },
                { type: "email", message: "Enter a valid email address." },
              ]}
            >
              <Input placeholder="Enter Email" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Payment Type"
              name="paymentType"
              rules={[{ required: true, message: "Payment Type is required." }]}
            >
              <Select placeholder="Select Payment Type">
                <Option value="monthly">Monthly</Option>
                <Option value="hourly">Hourly</Option>
                <Option value="weekly">Weekly</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Amount Per Type"
              name="amountPerType"
              value={parseInt(record.finalBudget)}
              rules={[
                { required: true, message: "Amount per type is required." },
              ]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Joining Date"
              name="candidateJoiningDate"
              rules={[{ required: true, message: "Joining Date is required." }]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default CandidateForm;

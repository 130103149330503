import { postReq } from "../api";
import React, { useEffect, useState } from "react";
import {
  Card,
  Descriptions,
  Typography,
  Divider,
  Tag,
  Button,
  Form,
  Input,
  Modal,
  message,
  Spin,
  Empty,
} from "antd";
import { CloseOutlined, FileOutlined, PlusOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";

const { Title } = Typography;

const CompnayProfilePage = () => {
  const [profileData, setProfileData] = useState({
    name: "",
    companyEmail: "",
    establishmentYear: "",
    companyWebsite: "",
    companyStrength: "",
    technologyNiche: [],
    requiredTech: [],
    msaDocument: "",
    ndaDocument: "",
    isAccountVerified: false,
  });
  // {
  //   name: "Cloudstakes Technology",
  //   companyEmail: "karanjaink8899@gmail.com",
  //   establishmentYear: "2018",
  //   companyWebsite: "https://cloudstakes.com",
  //   companyStrength: "50+",
  //   technologyNiche: ["IT Service", "DevOps", "DotNet Core"],
  //   msaDocument: "ftm-fleet-owner-performance-report-7_wpd57.pdf",
  //   ndaDocument: "ftm-fleet-owner-performance-report-7_qtwxi.pdf",
  //   isAccountVerified: true,
  // });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRequiredModalVisible, setIsRequiredModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchCompanyProfile();
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const showRequiredTechModal = () => {
    setIsRequiredModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsRequiredModalVisible(false);
    // form.resetFields();
  };

  const handleAddNiche = async (values) => {
    const { newNiche } = values;
    if (profileData.technologyNiche.includes(newNiche)) {
      message.warning("Technology niche already exists!");
    } else {
      const result = await postReq("/common/add-tech-niche", {
        value: values.newNiche,
      });
      if (result.status == 0) {
        message.warning("Unable to create the required tech tag");
      } else if (result.status == 1) {
        setProfileData((prevData) => ({
          ...prevData,
          technologyNiche: [
            ...prevData.technologyNiche,
            { label: newNiche, value: newNiche },
          ],
        }));
        message.success("Technology niche added successfully!");
      }
    }
    handleCancel();
  };
  const handleRequiredeDelete = async (id) => {
    const question = await Swal.fire({
      title: "Are you sure you want to Delete Required Tech?",
      text: "You will be delete tech from the application permanently!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete!",
    });

    if (question.isConfirmed) {
      const result = await postReq("/common/delete-required-technology", {
        _id: id,
      });
      if (result.status == 0) {
        message.warning("Unable to Delete the Required tech");
      } else if (result.status == 1) {
        setProfileData((prevData) => ({
          ...prevData,
          requiredTech: prevData.requiredTech.filter(
            (niche) => niche._id !== id
          ),
        }));
        message.success("Technology Deleted successfully!");
      }
    }
  };
  const handleNicheDelete = async (id) => {
    const question = await Swal.fire({
      title: "Are you sure you want to Delete niche?",
      text: "You will be delete niche from the application permanently!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete!",
    });

    if (question.isConfirmed) {
      const result = await postReq("/common/delete-tech-niche", {
        _id: id,
      });
      if (result.status == 0) {
        message.warning("Unable to Delete the niche");
      } else if (result.status == 1) {
        setProfileData((prevData) => ({
          ...prevData,
          technologyNiche: prevData.technologyNiche.filter(
            (niche) => niche._id !== id
          ),
        }));
        message.success("Technology niche Deleted successfully!");
      }
    }
  };
  const handleAddRequiredTech = async (values) => {
    const { newTech } = values;
    console.log(newTech);
    if (profileData.requiredTech.includes(newTech)) {
      message.warning("Technology niche already exists!");
    } else {
      const result = await postReq("/common/add-required-technology", {
        value: values.newTech,
      });
      if (result.status == 0) {
        message.warning("Unable to create the required tech tag");
      } else if (result.status == 1) {
        setProfileData((prevData) => ({
          ...prevData,
          requiredTech: [
            ...prevData.requiredTech,
            { label: newTech, value: newTech },
          ],
        }));
        message.success("Technology niche added successfully!");
      }
    }
    handleCancel();
  };

  const fetchCompanyProfile = async () => {
    console.log("1");
    setIsLoading(true);
    try {
      console.log("2");
      const response = await postReq("/company/profile", { role: "company" });
      console.log("3");
      if (response?.status === 0) {
        message.warning("Error finding company data");
        console.log("4");
      } else {
        setProfileData(response.data);
      }
    } catch (error) {
      message.error("Failed to fetch company profile: " + error.message);
      console.log("5");
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    console.log("6");
    return (
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Spin size="large" />
      </div>
    );
  }

  if (!profileData) {
    console.log("7");
    return (
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Empty description="No company profile data available" />
      </div>
    );
  }
  console.log(profileData.technologyNiche);
  console.log("8");

  return (
    <Card
      style={{
        width: "100%",
        margin: "20px auto",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: "10px",
      }}
    >
      <Title level={4} style={{ marginBottom: 20 }}>
        Company Profile
      </Title>
      <Divider />
      {console.log("9")}
      <Descriptions column={2} bordered size="middle">
        <Descriptions.Item label="Company Name">
          {profileData.name}
        </Descriptions.Item>
        <Descriptions.Item label="Email">
          <a href={`mailto:${profileData.companyEmail}`}>
            {profileData.companyEmail}
          </a>
        </Descriptions.Item>
        <Descriptions.Item label="Established Year">
          {profileData.establishmentYear}
        </Descriptions.Item>
        <Descriptions.Item label="SPOC Email">
          <a href={`mailto:${profileData.contactPerson}`}>
            {profileData.contactPerson}
          </a>
        </Descriptions.Item>

        <Descriptions.Item label="Address">
          {profileData?.companyAddress}
        </Descriptions.Item>
        <Descriptions.Item label="GstNo.">
          {profileData?.gstNo}
        </Descriptions.Item>
        <Descriptions.Item label="pan No.">
          {profileData?.panNo}
        </Descriptions.Item>
        <Descriptions.Item label="Aadhar No.">
          {profileData?.aadharNo}
        </Descriptions.Item>
        <Descriptions.Item label="Website">
          <a
            href={`http://${profileData.companyWebsite}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {profileData.companyWebsite}
          </a>
        </Descriptions.Item>
        <Descriptions.Item label="Company Strength">
          {profileData.companyStrength}
        </Descriptions.Item>

        <Descriptions.Item label="Technology Niche" span={2}>
          {profileData?.technologyNiche.length > 0
            ? profileData.technologyNiche.map((niche, index) => (
                <Tag key={index} color="blue" style={{ marginBottom: "4px" }}>
                  {niche.label}
                  <CloseOutlined
                    size={20}
                    style={{ marginLeft: "5px" }}
                    onClick={() => {
                      handleNicheDelete(niche._id);
                    }}
                  />
                </Tag>
              ))
            : ""}
        </Descriptions.Item>

        <Descriptions.Item label="Required Technology" span={2}>
          {profileData?.requiredTech.length > 0
            ? profileData.requiredTech.map((niche, index) => (
                <Tag key={index} color="blue" style={{ marginBottom: "4px" }}>
                  {niche.label}
                  <CloseOutlined
                    size={20}
                    style={{ marginLeft: "5px" }}
                    onClick={() => {
                      handleRequiredeDelete(niche._id);
                    }}
                  />
                </Tag>
              ))
            : ""}
        </Descriptions.Item>
        {/* <Descriptions.Item label="Account Verified">
          <Tag color={profileData.isAccountVerified ? "green" : "red"}>
            {profileData.isAccountVerified ? "Verified" : "Not Verified"}
          </Tag>
        </Descriptions.Item> */}
        <Descriptions.Item label="MSA Document">
          <a
            href={`https://${process.env.REACT_APP_BUCKET_URL}${profileData.msaDocument}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FileOutlined /> {profileData.msaDocument}
          </a>
        </Descriptions.Item>
        <Descriptions.Item label="NDA Document">
          <a
            href={`https://${process.env.REACT_APP_BUCKET_URL}${profileData.ndaDocument}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FileOutlined /> {profileData.ndaDocument}
          </a>
        </Descriptions.Item>
      </Descriptions>

      <Button
        type="primary"
        icon={<PlusOutlined />}
        style={{ marginTop: "20px" }}
        onClick={showModal}
      >
        Add Technology Niche
      </Button>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        style={{ marginTop: "20px", marginLeft: "20px" }}
        onClick={showRequiredTechModal}
      >
        Add Required Tech.
      </Button>

      <Modal
        title="Add Technology Niche"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={handleAddNiche}>
          <Form.Item
            label="Technology Niche"
            name="newNiche"
            rules={[
              { required: true, message: "Please enter a technology niche!" },
            ]}
          >
            <Input placeholder="Enter technology niche" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginRight: "10px" }}
            >
              Add
            </Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Add Required Technology"
        visible={isRequiredModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={handleAddRequiredTech}>
          <Form.Item
            label="Required Technology"
            name="newTech"
            rules={[
              {
                required: true,
                message: "Please enter a required technology!",
              },
            ]}
          >
            <Input placeholder="Enter Required technology" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginRight: "10px" }}
            >
              Add
            </Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
};

export default CompnayProfilePage;

import React, { useState } from "react";
import {
  Card,
  Tag,
  Typography,
  Row,
  Col,
  Space,
  Modal,
  Button,
  Upload,
  Input,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useLocation, useHistory } from "react-router-dom";
import { postReq } from "../../api";
import { toastNotification } from "../../utils/toasthelper";

const { Title, Text } = Typography;

const JobCard = () => {
  const location = useLocation();
  const history = useHistory();
  const data = location.state.data;
  const [applyModal, setApplyModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [finalBudget, setFinalBudget] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showFull, setShowFull] = useState(false);
  const toggleShowMore = () => setShowFull((prev) => !prev);
  const handleApplyClick = () => {
    setApplyModal(true);
  };
  const handleFileChange = ({ file }) => {
    setSelectedFile(file); // Update selected file state
  };
  const handleBudgetChange = (e) => {
    setFinalBudget(e.target.value);
  };
  const handleCancel = () => {
    setApplyModal(false);
    setSelectedFile("");
    setFinalBudget(0);
  };
  return (
    <>
      <Modal
        open={applyModal}
        className="cvUpdate"
        style={{ borderRadius: "10px" }}
        onCancel={handleCancel} // Ensure this closes the modal
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isLoading}
            onClick={async () => {
              try {
                setIsLoading(true);
                if (!selectedFile || !finalBudget) {
                  toastNotification(
                    "error",
                    "Please upload a PDF and enter a budget."
                  );
                  return;
                } else {
                  const formData = new FormData();
                  formData.append("resume", selectedFile);
                  formData.append("budget", finalBudget);
                  formData.append("requirementId", data._id);

                  const applyWithResume = await postReq(
                    "/vendor/requirement/apply",
                    formData,
                    { "Content-Type": "multipart/form-data" }
                  );

                  if (applyWithResume.status === 1) {
                    toastNotification("success", applyWithResume.message);
                    setIsLoading(false);
                    handleCancel();
                  } else {
                    toastNotification("error", applyWithResume.message);
                    setIsLoading(false);
                    handleCancel();
                  }
                }
              } catch (error) {
                toastNotification("error", error.message);
                setIsLoading(false);
                handleCancel();
              }
            }}
          >
            OK
          </Button>,
        ]}
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px",
              // background: "#F4F4F4",
            }}
          >
            <div>
              <Title
                level={4}
                style={{
                  marginBottom: 0,
                  fontSize: "16px",
                  fontWeight: "700",
                }}
              >
                Apply for requirement
              </Title>
            </div>
            <svg
              width="28"
              height="28"
              viewBox="0 0 38 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={handleCancel}
              style={{ cursor: "pointer" }}
            >
              <circle cx="19" cy="19" r="19" fill="white" />
              <path
                d="M13.4854 13.4852L25.0001 25"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M25 13.4852L13.4853 25"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </div>
        }
      >
        {" "}
        <div style={{ marginTop: "16px", display: "flex", gap: "10px" }}>
          {/* Upload Field */}
          <div style={{ marginBottom: "16px" }}>
            <Upload
              accept=".pdf"
              beforeUpload={() => false}
              onChange={handleFileChange}
            >
              <Button icon={<UploadOutlined />}>Upload CV</Button>
            </Upload>
          </div>

          {/* Budget Field */}
          <Input
            type="number"
            placeholder="Enter Final Budget"
            onChange={handleBudgetChange}
            value={finalBudget}
          />
        </div>
      </Modal>
      <Card bordered={false} style={{ width: "100%", borderRadius: 10 }}>
        <Row justify="space-between" align="middle">
          <Col style={{ display: "flex", gap: "8px", alignItems: "center" }}>
            <svg
              width="12"
              height="19"
              viewBox="0 0 12 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => {
                history.push("/vendor/requirement");
              }}
              style={{ fontSize: "20px", cursor: "pointer" }}
            >
              <path
                d="M10.5 2L3 9.5L10.5 17"
                stroke="#666666"
                stroke-width="3"
                stroke-linecap="round"
              />
            </svg>

            <Title
              level={4}
              style={{ margin: 0, fontSize: "20px", fontWeight: "700" }}
            >
              {data.requirementName}
            </Title>
            <svg
              width="4"
              height="24"
              viewBox="0 0 4 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              cursor="pointer"
            >
              <path
                d="M2 2L2 26"
                stroke="#D6D6D6"
                stroke-width="3"
                stroke-linecap="round"
              />
            </svg>

            <Text>
              {data.minExperience} to {data.maxExperience} Years
            </Text>
            <svg
              width="4"
              height="24"
              viewBox="0 0 4 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              cursor="pointer"
            >
              <path
                d="M2 2L2 26"
                stroke="#D6D6D6"
                stroke-width="3"
                stroke-linecap="round"
              />
            </svg>
            <Tag
              color="#E7F1FC"
              style={{
                border: "1px solid #C1D6EE",
                borderRadius: "12px",
                padding: "1px 8px",
                fontSize: "10px",
              }}
            >
              <span style={{ color: "#13487D", fontWeight: "600" }}>
                Job ID {data.jobId}
              </span>
            </Tag>
          </Col>
          {data.requirementStatus === "open" && (
            <Col>
              <svg
                width="44"
                height="44"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                cursor="pointer"
                onClick={() => handleApplyClick()}
              >
                <rect width="64" height="64" rx="18" fill="#F4F4F4" />
                <path
                  d="M31.0871 29.4692L30.051 32.5787C29.9906 32.7694 29.9837 32.973 30.0311 33.1674C30.0785 33.3617 30.1785 33.5392 30.3199 33.6806C30.4614 33.822 30.6391 33.9218 30.8334 33.9691C31.0277 34.0164 31.2313 34.0093 31.422 33.9487L34.5312 32.9126C34.6903 32.8591 34.8349 32.7698 34.9539 32.6514L42.2539 25.3506C42.7317 24.8723 43 24.2238 43 23.5477C43 22.8716 42.7317 22.2231 42.2539 21.7447C41.7753 21.2678 41.1273 21 40.4517 21C39.776 21 39.128 21.2678 38.6494 21.7447L31.3493 29.0465C31.2303 29.1652 31.1406 29.3099 31.0871 29.4692Z"
                  fill="#13487D"
                />
                <path
                  d="M41.9 28.7C41.6083 28.7 41.3285 28.8159 41.1222 29.0222C40.9159 29.2285 40.8 29.5083 40.8 29.8V37.5C40.8 38.3752 40.4523 39.2146 39.8335 39.8335C39.2146 40.4523 38.3752 40.8 37.5 40.8H26.5C25.6248 40.8 24.7854 40.4523 24.1665 39.8335C23.5477 39.2146 23.2 38.3752 23.2 37.5V26.5C23.2 25.6248 23.5477 24.7854 24.1665 24.1665C24.7854 23.5477 25.6248 23.2 26.5 23.2H34.2C34.4917 23.2 34.7715 23.0841 34.9778 22.8778C35.1841 22.6715 35.3 22.3917 35.3 22.1C35.3 21.8083 35.1841 21.5285 34.9778 21.3222C34.7715 21.1159 34.4917 21 34.2 21H26.5C25.0418 21.0017 23.6439 21.5818 22.6128 22.6128C21.5818 23.6439 21.0017 25.0418 21 26.5V37.5C21.0017 38.9582 21.5818 40.3561 22.6128 41.3872C23.6439 42.4182 25.0418 42.9983 26.5 43H37.5C38.9582 42.9983 40.3561 42.4182 41.3872 41.3872C42.4182 40.3561 42.9983 38.9582 43 37.5V29.8C43 29.5083 42.8841 29.2285 42.6778 29.0222C42.4715 28.8159 42.1917 28.7 41.9 28.7Z"
                  fill="#13487D"
                />
              </svg>
              {/* </Space> */}
            </Col>
          )}
        </Row>

        <Row style={{ marginTop: 15 }}>
          <Col>
            <Space>
              <svg
                width="14"
                height="18"
                viewBox="0 0 14 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.75 0C3.0285 0 0 3.0495 0 6.79875C0 12.126 6.1155 17.6265 6.37575 17.8575C6.483 17.9527 6.6165 18 6.75 18C6.8835 18 7.017 17.9528 7.12425 17.8582C7.3845 17.6265 13.5 12.126 13.5 6.79875C13.5 3.0495 10.4715 0 6.75 0ZM6.75 10.5C4.68225 10.5 3 8.81775 3 6.75C3 4.68225 4.68225 3 6.75 3C8.81775 3 10.5 4.68225 10.5 6.75C10.5 8.81775 8.81775 10.5 6.75 10.5Z"
                  fill="#A7A7A7"
                />
              </svg>

              <Text
                style={{
                  color: "#666666",
                  marginBottom: "6px",
                  fontSize: "18px",
                }}
              >
                {data.jobLocation}
              </Text>
              <svg
                width="4"
                height="24"
                viewBox="0 0 4 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                cursor="pointer"
              >
                <path
                  d="M2 2L2 26"
                  stroke="#D6D6D6"
                  stroke-width="3"
                  stroke-linecap="round"
                />
              </svg>
              <Text>{data.workType}</Text>
            </Space>
          </Col>
        </Row>

        <div
          style={{
            border: "1.5px dashed #D6D6D6",
            borderRadius: "12px",
            padding: "16px",
            marginTop: "16px",
          }}
          px
        >
          <Row>
            <Col>
              <Text
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  marginBottom: "40px",
                }}
              >
                Job Description
              </Text>
            </Col>
            <Col span={24}>
              <Text
                style={{
                  fontSize: "16px",
                  color: "#666666",
                  whiteSpace: "pre-wrap",
                  marginTop: "20px",
                  maxHeight: showFull ? "100%" : "200px",
                  // overflow: "hidden",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: showFull ? "none" : 3, // Show full content if `showFull` is true
                  overflow: "auto",
                }}
              >
                {data.requirementJobDescription}
              </Text>
              <div>
                <a
                  onClick={toggleShowMore}
                  style={{
                    color: "#1890ff",
                    marginTop: "10px",
                    display: "block",
                    cursor: "pointer",
                  }}
                >
                  {showFull ? "Show Less" : "Show More"}
                </a>
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 30 }}>
            <Col>
              <Space size={[0, 8]} wrap>
                <Text style={{ color: "#666666", marginRight: "8px" }}>
                  Skill
                </Text>
                {data.requiredTechnology.map((data, i) => (
                  <Tag
                    style={{
                      key: { i },
                      color: "#13487D",
                      border: "1.5px solid #C1D6EE",
                      background: "transperant",
                      borderRadius: "16px",
                      padding: "4px 10px",
                      fontWeight: "600",
                      fontSize: "13px",
                    }}
                  >
                    {data.value}
                  </Tag>
                ))}
              </Space>
            </Col>
          </Row>

          <Row style={{ marginTop: 30 }}>
            <Col>
              <Text style={{ color: "#666666" }}>Budget for this role is </Text>
              <Text strong>INR {data.budget} +GST.</Text>
            </Col>
          </Row>
        </div>
      </Card>
    </>
  );
};

export default JobCard;

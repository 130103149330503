import ReactApexChart from "react-apexcharts";
import { Row, Col, Typography } from "antd";
import { useEffect, useState } from "react";
import { postReq } from "../../api";

function EChart({ toHandleData }) {
  const { Title, Paragraph } = Typography;
  const [chartData, setChartData] = useState([]);
  const [categories, setCategories] = useState([]);
  useEffect(async () => {
    const response = await postReq("/vendor/vendor_budget", {});
    const monthlyBudgets = new Array(12).fill(0);

    // Map API response to months
    response?.data?.forEach((item) => {
      if (item.month >= 1 && item.month <= 12) {
        monthlyBudgets[item.month - 1] = item.totalFinalBudget;
      }
      setCategories([
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ]);
    });

    setChartData(monthlyBudgets);
    if (toHandleData) {
      toHandleData(monthlyBudgets);
    }
  }, []);

  const items = [
    {
      Title: "3,6K",
      user: "Users",
    },
    {
      Title: "2m",
      user: "Clicks",
    },
    {
      Title: "$772",
      user: "Sales",
    },
    {
      Title: "82",
      user: "Items",
    },
  ];

  const eChart = {
    series: [
      {
        name: "Sales",
        // data: [450, 200, 100, 220, 500, 100, 400, 230, 500],
        data: chartData,
        color: "#fff",
      },
    ],

    options: {
      chart: {
        type: "bar",
        width: "100%",
        height: "auto",

        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          borderRadius: 5,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["transparent"],
      },
      grid: {
        show: true,
        borderColor: "#ccc",
        strokeDashArray: 2,
      },
      xaxis: {
        categories: categories,
        labels: {
          show: true,
          align: "right",
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: [
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
            ],
          },
        },
      },
      yaxis: {
        labels: {
          show: true,
          align: "left",
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: [
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
            ],
          },
        },
      },

      tooltip: {
        y: {
          formatter: function (val) {
            return "Rs." + val;
          },
        },
      },
    },
  };

  const currentMonth = new Date().getMonth() + 1;
  console.log("chartData", chartData, currentMonth);
  return (
    <>
      <div id="chart">
        <ReactApexChart
          className="bar-chart"
          options={eChart.options}
          series={eChart.series}
          type="bar"
          height={220}
        />
      </div>
      <div className="chart-vistior">
        <Title level={5}>
          Curent Month sales - {chartData[currentMonth - 1]}
        </Title>
        <Paragraph className="lastweek">
          compair to last Month{" "}
          <span className="bnb2">
            +
            {(
              (chartData[currentMonth - 1] / chartData[currentMonth - 2]) *
              100
            ).toFixed(2)}
            %
          </span>
        </Paragraph>
        <Paragraph className="lastweek">
          We have created multiple options for you to put together and customise
          into pixel perfect pages.
        </Paragraph>
        <Row gutter>
          {items.map((v, index) => (
            <Col xs={6} xl={6} sm={6} md={6} key={index}>
              <div className="chart-visitor-count">
                <Title level={4}>{v.Title}</Title>
                <span>{v.user}</span>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
}

export default EChart;

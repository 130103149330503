import React, { useState } from "react";
import { Card, Typography, Tag, Button, Space, Tooltip, Switch } from "antd";
import { postReq } from "../api";
const { Title, Text } = Typography;

const CardComponent = ({
  requirementName,
  jobId,
  requirementType,
  maxExperience,
  minExperience,
  jobLocation,
  requiredTechnology,
  requirementStatus,
  workType,
  vacancy,
  budget,
}) => {
  const firstTwoTechnology = requiredTechnology.slice(0, 2);
  const remainingTechnologyCount = requiredTechnology.length - 2;
  const remainingTechnology = requiredTechnology.slice(
    2,
    requiredTechnology.length
  );
  return (
    <Card
      className="cardComponent"
      style={{
        height: "100%",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "20px",
      }}
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background: "#3699FF",
            color: "black",
            padding: "16px",
            borderRadius: "20px 20px 0px 0px",
            margin: "0px , -24px",
          }}
        >
          <div>
            <Title
              level={4}
              style={{
                marginBottom: 0,
                fontSize: "16px",
                fontWeight: "500",
                color: "white",
              }}
            >
              {requirementName}
            </Title>
            <Text type="secondary" style={{ fontSize: "14px", color: "#fff" }}>
              {/* {workType} */}
              <Tag
                color="#E7F1FC"
                style={{
                  border: "1px solid #C1D6EE",
                  borderRadius: "12px",
                  padding: "1px 8px",
                  fontSize: "10px",
                }}
              >
                <span style={{ color: "#13487D", fontWeight: "600" }}>
                  Job ID {jobId}
                </span>
              </Tag>
            </Text>
          </div>
          {/* <div>
            <Switch
              checked={requirementStatus1}
              onChange={handleStatusChange}
              checkedChildren="Open"
              unCheckedChildren="Close"
              style={{
                backgroundColor: requirementStatus ? "#221ac4" : "#ff4d4f",
              }}
            />
          </div> */}
          <div>
            <Title
              level={4}
              style={{
                marginBottom: 0,
                color: "#fff",
                fontWeight: "500",
                fontSize: "14px",
              }}
            >
              <Tag
                color={
                  requirementStatus == "on-hold"
                    ? "yellow"
                    : requirementStatus == "open"
                    ? "#E7F1FC"
                    : requirementStatus == "close"
                    ? "red"
                    : ""
                }
                style={{
                  border: "0.5px solid #C1D6EE",
                  borderRadius: "10px",
                  padding: "1px 8px",
                  fontSize: "10px",
                }}
              >
                <span style={{ color: "#13487D", fontWeight: "600" }}>
                  {requirementStatus}
                </span>
              </Tag>
            </Title>
          </div>
        </div>
      }
    >
      <div style={{ marginTop: 1 }}>
        {/* <Tag
          color="#E7F1FC"
          style={{
            border: "2px solid #C1D6EE",
            borderRadius: "16px",
            padding: "2px 10px",
            fontSize: "12px",
          }}
        >
          <span style={{ color: "#13487D", fontWeight: "600" }}>
            Job ID {jobId}
          </span>
        </Tag> */}
        <div style={{ marginTop: 6, fontSize: "12px" }}>
          <Text>
            <strong>Job Type :</strong>
            <span style={{ color: "#666666" }}> {requirementType}</span>
          </Text>
        </div>

        {/* Experience */}
        <div style={{ marginTop: 12, fontSize: "12px" }}>
          <Text>
            <strong>Experience :</strong>
            <span
              style={{ color: "#666666" }}
            >{` ${minExperience} to ${maxExperience} years`}</span>
          </Text>
        </div>

        {/* Location */}
        <div style={{ marginTop: 12, fontSize: "12px" }}>
          <Text>
            <strong>Location :</strong>

            <span style={{ color: "#666666" }}>
              {jobLocation} | {workType}
            </span>
          </Text>
        </div>
        <div style={{ marginTop: 12, fontSize: "12px" }}>
          <Text>
            <strong>Vacancy :</strong>
            <span style={{ color: "#666666" }}> {vacancy}</span>
          </Text>
        </div>
        <div style={{ marginTop: 12, fontSize: "12px" }}>
          <Text>
            <strong>Budget :</strong>
            <span style={{ color: "#666666" }}>
              {" "}
              {budget} <span style={{ fontSize: "10px" }}>+GST.</span>
            </span>
          </Text>
        </div>

        {/* Skills */}
        <div
          style={{
            marginTop: 12,
            fontSize: 12,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Text
            style={{
              whiteSpace: "nowrap",
              marginRight: "5px",
            }}
          >
            <strong>Skill:</strong>
          </Text>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <div
              // size={"small"}
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "0px",
              }}
            >
              {firstTwoTechnology?.map((data) => (
                <Tag
                  style={{
                    borderRadius: "20px",
                    color: "#13487D",
                    fontWeight: "600",
                    border: "2px solid #C1D6EE",
                  }}
                >
                  {data?.value}
                </Tag>
              ))}
              {remainingTechnologyCount > 0 && (
                <Tooltip
                  placement="top"
                  title={
                    <div
                      style={{
                        gap: "4px",
                        maxWidth: "200px",
                        wordWrap: "break-word",
                      }}
                    >
                      {remainingTechnology.map((data, i) => (
                        <span key={i} style={{ whiteSpace: "nowrap" }}>
                          <p style={{ fontSize: "12px", margin: 0 }}>
                            {data.value}
                          </p>
                        </span>
                      ))}
                    </div>
                  }
                >
                  <Button
                    type="primary"
                    shape="circle"
                    style={{
                      backgroundColor: "#1A90FF",
                      border: "none",
                      borderRadius: "16px",
                      width: "18px",
                      height: "22px",
                      fontWeight: "bold",
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                      boxShadow: "0px 4px 6px rgb(26,144,255,0.3)",
                    }}
                  >
                    +{remainingTechnologyCount}
                  </Button>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default CardComponent;

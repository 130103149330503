import React, { useState } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Select,
  Space,
  Typography,
  Checkbox,
} from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { postReq } from "../../api";
import { toastNotification } from "../../utils/toasthelper";

const { Title } = Typography;
const { TextArea } = Input;

const EditVendorProfile = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();
  const { data } = location.state;
  const onFinish = async (values) => {
    setLoading(true);
    try {
      const payload = { ...values, _id: data._id };

      const response = await postReq("/vendor/update-profile", payload);
      if (response.status === 1) {
        setLoading(false);
        toastNotification("success", response.message);
        history.push("/vendor/profile");
      } else {
        setLoading(false);
        toastNotification("error", response.message);
      }
    } catch (error) {
      setLoading(false);
      toastNotification("error", error.message);
    }
  };

  return (
    <Card
      style={{
        width: "90%",
        margin: "20px auto",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: "10px",
        overflow: "hidden",
      }}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        {/* Title Section */}
        <Title level={4}>Edit Vendor Profile</Title>

        <Form
          form={form}
          layout="vertical"
          initialValues={data}
          onFinish={onFinish}
        >
          {/* Basic Information */}
          <Form.Item
            label="Vendor Name"
            name="name"
            rules={[{ required: true, message: "Please enter vendor name" }]}
          >
            <Input placeholder="Enter Vendor Name" />
          </Form.Item>

          <Form.Item
            label="Vendor Company Name"
            name="vendorCompanyName"
            rules={[{ required: true, message: "Please enter company name" }]}
          >
            <Input placeholder="Enter Vendor Company Name" />
          </Form.Item>

          <Form.Item
            label="Company Strength"
            name="vendorCompanyStrength"
            rules={[
              { required: true, message: "Please enter company strength" },
            ]}
          >
            <Input placeholder="Enter Company Strength" />
          </Form.Item>

          <Form.Item
            label="Vendor Email"
            name="vendorEmail"
            rules={[
              { type: "email", message: "Please enter a valid email" },
              { required: true, message: "Please enter email" },
            ]}
          >
            <Input placeholder="Enter Vendor Email" />
          </Form.Item>

          <Form.Item
            label="Company Website"
            name="vendorCompanyWebsiteUrl"
            rules={[
              { required: true, message: "Please enter company website" },
            ]}
          >
            <Input placeholder="Enter Company Website" />
          </Form.Item>

          {/* Legal Details */}
          <Form.Item
            label="MSME Number"
            name="msmeNo"
            rules={[{ required: true, message: "Please enter MSME number" }]}
          >
            <Input placeholder="Enter MSME Number" />
          </Form.Item>

          <Form.Item
            label="CIN Number"
            name="cinNO"
            rules={[{ required: true, message: "Please enter CIN number" }]}
          >
            <Input placeholder="Enter CIN Number" />
          </Form.Item>

          <Form.Item
            label="TAN Number"
            name="tanNo"
            rules={[{ required: true, message: "Please enter TAN number" }]}
          >
            <Input placeholder="Enter TAN Number" />
          </Form.Item>

          <Form.Item
            label="CEO PAN Number"
            name="ceoPanNo"
            rules={[{ required: true, message: "Please enter CEO PAN number" }]}
          >
            <Input placeholder="Enter CEO PAN Number" />
          </Form.Item>

          <Form.Item
            label="CEO Aadhar Number"
            name="ceoAdharNo"
            rules={[
              { required: true, message: "Please enter CEO Aadhar number" },
            ]}
          >
            <Input placeholder="Enter CEO Aadhar Number" />
          </Form.Item>

          <Form.Item
            label="Company GST Number"
            name="vendorCompanyGstNo"
            rules={[{ required: true, message: "Please enter GST number" }]}
          >
            <Input placeholder="Enter GST Number" />
          </Form.Item>

          {/* Address Details */}
          <Form.Item
            label="Registered Address"
            name="RegAddress"
            rules={[
              { required: true, message: "Please enter registered address" },
            ]}
          >
            <TextArea rows={2} placeholder="Enter Registered Address" />
          </Form.Item>

          <Form.Item
            label="Operational Address"
            name="operationalAddress"
            rules={[
              { required: true, message: "Please enter operational address" },
            ]}
          >
            <TextArea rows={2} placeholder="Enter Operational Address" />
          </Form.Item>

          <Form.Item
            label="ISO Certification Number"
            name="isoNo"
            rules={[
              {
                required: true,
                message: "Please enter ISO certification number",
              },
            ]}
          >
            <Input placeholder="Enter ISO Certification Number" />
          </Form.Item>

          {/* ITR Numbers */}
          <Form.Item label="ITR No. 1" name="itrNo1">
            <Input placeholder="Enter ITR No. 1" />
          </Form.Item>

          <Form.Item label="ITR No. 2" name="itrNo2">
            <Input placeholder="Enter ITR No. 2" />
          </Form.Item>

          <Form.Item label="ITR No. 3" name="itrNo3">
            <Input placeholder="Enter ITR No. 3" />
          </Form.Item>

          {/* Additional Details */}

          {/* Submit Button */}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginTop: "20px", width: "100%" }}
              loading={loading}
            >
              Update Profile
            </Button>
          </Form.Item>
        </Form>
      </Space>
    </Card>
  );
};

export default EditVendorProfile;
